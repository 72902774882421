// Stupidly crappy resolutions
@media (min-width:1441px) and (max-width: 1640px) {
	
	.slideshow .region-slide-show .front-page-slideshow .view-content .views_slideshow_main .views-row .content {

    top: 29%;
  }
}
@media (max-width: 1400px) {

  .owl-carousel {

    .owl-item {

      img {
        height: auto;
      }
    }
  }

  .front-page-services {

    .block__content {

      .quicktabs-tabpage {

        .view {

          .view-content {

            .services-catagories {

              .views-row {
                max-width: unset;
                max-height: unset;
              }
            }
          }
        }
      }
    }
  }

  .page-node-34 {

    .separator {

      .left {
        padding: 0 1%;
        font-size: 1em;

        h1 {
          font-size: 1.7em;
          margin-bottom: 2rem;
          margin-top: 1rem;
        }
      }
    }
  }
}


@media (min-width:1201px) and (max-width: 1440px) {
	

  .page-node-34 .separator .left, .page-node-64 .separator .left {

    padding: 0 1%;
   h1 {

    margin-bottom: 1rem;
    font-size: 27px;
   } 
  }

}


@media (max-width: 1200px)  {

  .front {
    
    .slideshow {

      .region-slide-show {

        .front-page-slideshow {

          .view-content {

            .views-slideshow-cycle-processed {

              .views-slideshow-controls-bottom {

                .views-slideshow-pager-fields {

                  .views-slideshow-pager-field-item {

                    &::before {
                      font-size: 3em;
                    }

                    a {
                      padding: 4rem 1rem 4rem 7rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .front-page-blog {

      .block__content {

        .view-content {

          .views-row {

            .image-title {

              .views-field-field-image {

                .field-content {

                  img {
                    width: 300px;
                    height: 300px;
                  }
                }
              }
            }
            .body-link {
              height: 300px;

              .views-field-body {

                p {
                  font-size: 1.8rem;
                }
              }
            }
          }
        }
      }
    }

    .fourth-content {
      
      video {
        top: -50px;
      }
    }
  }

  .page-node-34 {

    .block--about-farah-jahed {

      .block__title {
        font-size: 1.5em;
        line-height: 30px;
        margin-bottom: 2rem;
      }
      .block__content {

        p {
          line-height: 22px;
          margin: 0 0 10px;
          font-size: 14px;
        }
      }
    }
  }

  .about-certificates {

    .block__content {

      .view-content {

        .owl-carousel {

          .owl-stage-outer {

            .owl-stage {

              .owl-item>div {

                .views-field-field-image {
                  min-height: 220px;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }

}


@media (min-width:992px) and (max-width: 1200px)  {

  .slideshow .region-slide-show .front-page-slideshow .view-content .views-slideshow-cycle-processed .views-slideshow-controls-bottom .views-slideshow-pager-fields .views-slideshow-pager-field-item:nth-child(4):before {

    font-size: 3em;
  }
  // .front-page-services {

    
  //   position: relative;
  //   .block__content {

  //     #quicktabs-services {

  //       position: relative;
  //       .item-list {

  //         position: static;
  //         bottom: unset;
  //         .quicktabs-tabs {

  //           flex-direction: row; 
  //           justify-content: center;
  //           li {

  //             display: flex;
  //             a {
                
  //               border-left: unset;
  //               border-bottom: solid 3px rgba(220, 223, 229, 0.48);
  //             }
  //           }
  //         }
  //       }

  //     }
  //     .quicktabs-tabpage {

  //       .view {

  //         .view-header {

  //           display: none;
  //         }
  //         .view-content {

  //           max-width: unset;
  //           margin-left: auto;
  //         }
  //         .view-footer {

  //           max-width: 260px;
  //           position: absolute;
  //           left: 0;
  //           top: 15rem;
  //           display: none;
  //           h2 {

  //             font-size: 35px;
  //             line-height: 40px;
  //             font-weight: 600;
  //             letter-spacing: -2px;
  //             margin: -3px 0 30px;
  //             color: #23282d;
  //             font-weight: 500;
  //           }
  //           p {

  //             font-size: 14px;
  //             line-height: 30px;
  //             color: #222222;
              
  //           }
  //         }
  //         &.view-display-id-block_5 {

  //           .view-content {
              
  //             padding: 1rem 0;
  //             display: flex;
  //             .services-catagories  {

  //               display: flex;
  //               justify-content: space-between;
  //             }
  //             .views-row {

  //               margin-right: auto;
  //               .views-field-view-node a:nth-child(1) {
    
  //                 // display: block;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

@media (min-width:769px) {
  body {

    #mm-0 {
      width: unset;
    }
  }
}