@media (max-width:480px) {

  header .top-header .container {


    .social-media {

      display: none;
    }
    .topheader-contacts {
      
      &.language {

        margin-left: 0;
        display: none;
      }
      &.email {
  
        margin-left: 0;
        display: flex;
        // display: none;
      }
      &.phone {

        margin-left: auto;
      }
    }
  }
  .slideshow {

    padding-bottom: 0;
    .region-slide-show .front-page-slideshow .view-content .views-slideshow-cycle-processed {
      
      .views_slideshow_main .views-row .content .views-field-title .field-content {
  
        letter-spacing: 0;
        font-size: 50px;
        line-height: 60px;
      }
      .views_slideshow_main .views-row .content .views-field-field-description .field-content {

        font-size: 3rem;
        line-height: 3.5rem;
      }
      .views-slideshow-controls-bottom {
    
        display: none;
      }
    }
  }
  .front .region-content {

    margin-top: 3rem;
    margin-bottom: 0;
  }
  .front-page-solution .block__content {

    .view-header {
  
      margin: 1rem 0;
    }
    .view-content {

      .views-slideshow-cycle-processed .views_slideshow_cycle_main .views-slideshow-cycle-main-frame {

        height: auto !important;
        .views-slideshow-cycle-main-frame-row {

          position: relative !important;
          height: auto !important;
          .views-row {
            
            flex-direction: column;
            img {

              width: 100%;
              height: auto;
            }
          }
        }
      }
      .views-slideshow-cycle-processed .views-slideshow-controls-top .views-slideshow-pager-fields .views-slideshow-pager-field-item .views-field-title .views-content-title a {
        padding: 0.7rem 0rem;
      }
    }
  }
  .front-page-services .block__content {

    .quicktabs-tabpage .view .view-content .services-catagories .views-row {

      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 2rem;
      max-height: unset;
    }

    #quicktabs-services .item-list .quicktabs-tabs li a {

      padding: 0.7rem 0rem;      
    }
    .quicktabs-tabpage .view .view-header p {
  
      // font-size: 26px;
      line-height: 35px;
    }
  }
  .front .sixth-content .container a {
    position: relative;
    right: unset;
    bottom: unset;
  }
  .front-page-testimonials {

    padding: 1rem;
    .block__title {

      letter-spacing: 0 !important;
    }
    .view-content .owl-carousel .owl-stage-outer .owl-stage .owl-item>div .bottom {

      padding-left: 2rem;
      &:before {

        width: 1rem;
      }
    }
  }
  .front-page-blog {

    padding: 1rem 0;

    .block__content .view-content .owl-carousel .owl-stage-outer .owl-stage {

      padding: 0;
    }
  }
  .front .fourth-content video {

    width: 170%;
    height: auto;
    position: absolute;
    left: -30%;
  }
  .join .container {

    flex-direction: column;
    p {

      width: 100%;
    }
  }
  .front-page-separator .block__title {

    font-size: 21px;
  }
  .front-page-blog .block__content .view-content {

    flex-direction: column;
    justify-content: center;
    .views-row {

      margin-bottom: 3rem;
      .views-field-title {

        margin-top: 2rem;
      }
    }
  }

  
  .page-node-120 .separator .solutions-page-separator .block__content .view-content {

    flex-direction: column;
  }
  .page-node-34 .node-page .field-name-body .field-items .field-item .bottom .items , 
  .page-node-64 .node-page .field-name-body .field-items .field-item .bottom .items {

    flex-direction: column;
  }
  .page-taxonomy-term-1 #main-wrapper #content .page-content .region-content .view-display-id-page .view-content .views-row, .page-taxonomy-term-2 #main-wrapper #content .page-content .region-content .view-display-id-page .view-content .views-row, .page-taxonomy-term-3 #main-wrapper #content .page-content .region-content .view-display-id-page .view-content .views-row, .page-taxonomy-term-30 #main-wrapper #content .page-content .region-content .view-display-id-page .view-content .views-row {

    flex-basis: 100%; 
  }
  .node-type-services {

    .pages-with-sidebar {

      flex-direction: column-reverse;
    }
    .page-header {

      .field-name-field-image {

        height: 250px;
      }
      .text {

        padding-left: 1rem !important;
        padding-bottom: 0rem !important;
        h1 {

          font-size: 22px;
          margin-bottom: 1rem;
        }
      }
    }
  }
  .page-articles #content .page-content .region-content .view-display-id-page .view-content .views-row {

    flex-direction: column;
    img {

      width: 100%;
      height: auto;
    }
  }
  .node-type-articles #content .page-content .region-content .col-sm-12 .field-type-image {

    img {

      width: 100%;
      height: auto;
    }
  }
  .page-node-35 #seventh_content .region-seventh-content .block-webform, .page-node-66 #seventh_content .region-seventh-content .block-webform {

    padding: 0 1rem;
    margin-top: 2rem;
  }
  #footer .footer-blocks {

    flex-basis: 100%;
    &.footer-social-media {

      top: 100%;
      width: 97%;
    }
  }
  #footer .top-footer {
    padding-bottom: 7rem;
  } 






  .front {

    .slideshow {

      .region-slide-show {

        .front-page-slideshow {

          .view-content {

            .views-slideshow-cycle-processed {

              .views_slideshow_main {

                .views-row {

                  .content {
                    top: 10%;

                    .views-field-field-link {

                      a {
                        padding: 20px 25px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .front-page-blocks.title-lines {

      .block__title {
        font-size: 0.9em;
      }
    }

    .front-page-solution {

      .block__content {

        .view-content {

          .views-slideshow-cycle-processed {

            .views-slideshow-controls-top {

              .views-slideshow-pager-fields {

                .views-slideshow-pager-field-item {

                  .views-field-title {

                    .views-content-title {

                      a {
                        text-align: center;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .front-page-blog {

      .block__content {

        .view-content {

          .views-row {
            display: unset;
          }
          .views-row {
            
            .image-title {

              .views-field-field-image {

                .field-content {
                  
                  img {
                    width: 381px;
                    height: 381px;
                  }
                }
              }
            }
            .body-link {
              height: 381px;

              .views-field-body {

                p {
                  font-size: 1.8rem;
                }
              }
            }
          }
        }
      }
    }

    #fourth_content {
      height: 392px;

      .front-page-separator {

        .block__title {
          font-size: 3em;
        }
        .block__content {
          margin-top: 2rem;

          .links {
            margin-top: 2rem;
          }
        }
      }
    }

    .sixth-content {

      .container {

        a {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  ul.quicktabs-tabs {

    li {
      margin: 0 0 0.25em 0.5em;
    }
  }

  .block__content {

    #quicktabs-services {

      .item-list {
        top: 15rem;
      }
    }
  }

  .front-page-services {

    .block__content {

      #quicktabs-services {

        .item-list {
          top: 15rem;
        }
      }
    }
  }

  .front-page-blocks.title-lines {

    .block__title {
      text-align: center;
    }
  }

  .join {

    .container {

      a {
        width: 100%;
        justify-content: center;
        padding: 15px 20px;
        font-size: 1em;
        margin-top: 2rem;
      }
    }
  }

  .modal-join {

    .join-block-text {
      padding: 4rem;

      h3 {
        font-size: 2.7rem;
      }
    }
    .join-block {

      .form-actions {

        input {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .footer-blocks {

    .block__content {

      p {
        font-size: 13px;
      }
    }
  }

  #footer {

    .bottom-footer {

      p {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        padding: 0 1rem;
        
        img {
          margin-top: 1em;
        }
        a {
          margin-top: 1em;
        }
      }
    }
  }

  .page-node-120 {

    #content {

      .page-content {
        
        .field-item {
          padding: 0 1em;
        }
      }
    }
  }

  .front-page-services {
    .block__content #quicktabs-services .item-list .quicktabs-tabs li:nth-child(3) {

      display: none;
    }
    .block__content #quicktabs-services .item-list .quicktabs-tabs li:nth-child(4) {

      display: flex;
    }
  }
  .front-page-services .block__content .view-header p {

    text-align: center;
    color: #23282d;
    font-size: 20px;
    font-family: $font-family-base;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 24px;
    margin-top: 2rem;
  }
  .front-page-services .block__content #quicktabs-services .item-list {

    height: auto;
  }
  .front-page-services .block__content .quicktabs-tabpage .view .view-content {

    height: unset;
  }
  .front-page-solution .block__content .view-content .views-slideshow-cycle-processed {

    height: auto;
  }
  .front .front-page-solution .block__content .view-content .views-slideshow-cycle-processed .views-slideshow-controls-top .views-slideshow-pager-fields .views-slideshow-pager-field-item .views-field-title .views-content-title a {

    font-size: 0.9rem;
  }
  .front-page-services .block__content #quicktabs-services .item-list .quicktabs-tabs li a {

    font-size: 1rem;
  }
  .front-page-solution .block__content .view-content .views-slideshow-cycle-processed .views_slideshow_cycle_main .views-slideshow-cycle-main-frame .views-slideshow-cycle-main-frame-row .views-row .right .views-field-body .field-content p {

    flex-direction: column;
    align-items: flex-start;
    &:before {

      top: 14px;
    }
    &:after {

      top: 14px;
    }
  }
  .front .front-page-services .block__content #quicktabs-services .item-list {

    top: 20.5rem;
  }
  .front-page-services .block__content .quicktabs-tabpage .view .view-header {

    margin-bottom: 12rem;
  }
  .front-page-services .block__content .quicktabs-tabpage .view .view-content {

    max-height: unset;
  }
  .page-our-services .view-services.view-display-id-page .view-content .services-catagories .views-row {

    flex-basis: 100%;
    width: 100%;
  }
  .front #fourth_content .front-page-separator .block__title {
    font-size: 2rem;
  }
  .front .front-page-blog .block__content .view-content .views-row {

    margin-bottom: 2rem;
  }
  .front-page-blog .block__content .view-content .owl-carousel .owl-stage-outer .owl-stage .owl-item {

    max-width: unset;
  }
  .footer-blocks.footer-news-letter .webform-client-form>div .form-actions {

    right: 12px;
  }
  .no-js .owl-carousel, .owl-carousel.owl-loaded {

    display: flex;
    flex-direction: column;
  }
  .header-image .page-header h1 {
    font-size: 26px;
    line-height: 36px;
    text-align: center;
  }
  .pages-with-sidebar .page-content {
    padding: 0;
  }

  .node-type-articles #content .page-content .region-content .col-sm-12 .comment-wrapper form {
    width: 100%;
  }

  #seventh_content .captcha {
    margin-left: 15px;
  }
  .mm-menu_offcanvas.mm-menu_opened {
    z-index: 99;
  }
  .front .front-page-blog .block__content .view-content .views-row {
    max-width: 100%;
  }


  .page-node-120 .separator .solutions-page-separator .block__content .view-content .views-row, .page-node-140 .separator .solutions-page-separator .block__content .view-content .views-row {
    width: 100%;
  }
  .page-node-140 .separator .solutions-page-separator .block__content .view-content {
    flex-direction: column;
  }
}	


