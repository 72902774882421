// Typography
// -----------------------------------------------------------------------------

@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:100,300');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');
// icons8 fonts 
@font-face {
	font-family: icons;
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/icons/icons.eot');
	src: url('../fonts/icons/icons.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('../fonts/icons/icons.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('../fonts/icons/icons.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('../fonts/icons/icons.ttf') format('truetype');
}