// Styles for Header Menus and Navs
// -----------------------------------------------------------------------------
header {

  position: relative;
  z-index: 99;
  .top-header {
    
    color: $white;
    border-bottom: 1px solid rgba(255,255,255,.2);
    padding: 0.4rem 0;
    height: 35px;
    background-color: rgba(0,0,0,.2);
    background-color: $brand-primary;
    .container {

      display: flex;
      align-items: center;
      .topheader-contacts {

        display: flex;
        padding-right: 2rem;
        margin-left: 2rem;
        border-right: 1px solid rgba(255,255,255,.2);
        font-weight: 400;
        .icon {

          margin-right: 1.5rem;
        }
        a {

          color: $white;
        }
        &.email {

          margin-left: auto;
        }
        &.language {

          font-weight: 700;
          font-size: 1.5rem;
          border: none;
          // font-family: 'Open Sans';
          a {
            margin: 0 0.5rem;
          }
        }
      }
      .social-media { 

        margin-left: 1rem;
        display: flex;
        align-items: center;
        a {

          color: $white;
          font-size: 1.7rem;
          margin: 0 1rem;
          &.youtube {

            font-size: 2.3rem;
            &:hover {

              color: #a70505;
            }
          }
          &:hover {

            color: #a70505;
            text-decoration: none;
          }
        }
      }
    }
  }
  .bot-header {

    width: 100%;
    background-color: $white;
    &.headroom--pinned.headroom--not-top {

      transform: translateY(0%);
      .container .region-main-menu .block-responsive-menu .responsive-menu-block .horizontal-menu li a,
      .container .region-main-menu .block-ultimenu .block__content .ultimenu li a,
      .container .region-main-menu .block-ultimenu .block__content .ultimenu li .nolink {
    
        color: $brand-secondary;
        &.active {

          border-bottom: solid 1px $brand-primary;
        }
      }
    }
    .container {
      
      display: flex;
      align-items: center;
      position: relative;
      .navbar-brand {
  
        padding: 0;
        height: 45px;
      }
      .region-main-menu {
        
        margin-left: auto;
        .block-responsive-menu {
  

          display: none;
          .responsive-menu-block {
            
            .horizontal-menu {
  
              li {
  
                a {
  
                  color: $brand-primary;
                  padding: 10px 23px 9px;
                  border-radius: 25px;
                  letter-spacing: -.5px;
                  border: 2px solid transparent;
                  font-weight: 300;
                  font-size: 18px;
                  &.active {
  
                    border-color: rgba(255,255,255,.8);
                  }
                  &:hover {
  
                    color: #ec1111;
                  }
                }
              }
            }
          }
        }
        .block-ultimenu {

          position: static;
          .block__content {

            .ultimenu {

              margin-bottom: 0;
              position: static;
              li {

                >a,
                .nolink {

                  color: $brand-secondary;
                  padding: 25px 23px;
                  border-radius: 0;
                  letter-spacing: 0;
                  font-weight: 00;
                  font-size: 18px;
                  transition: all 0.3s ease-in-out;
                  &.active {
  
                    position: relative;
                    &:after {

                      content: '';
                      width: 100%;
                      height: 2px;
                      position: absolute;
                      bottom: 0px;
                      left: 0;
                      background-color: $brand-primary;
                    }
                  }
                  &:hover {
  
                    color: #ec1111;
                  }
                }
                .nolink {

                  padding: 0px 23px;
                }
                &.has-ultimenu {

                  position: static;
                  .ultimenu-flyout {
                    
                    // opacity: 1 !important;
                    // visibility: visible !important;
                    background-color: $white;
                    border-radius: 0;
                    width: $container-large-desktop;
                    z-index: 9;
                    padding: 1rem 2rem;
                    // background-image: url('../images/bg3.png');
                    background-size: 120%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: #f3f3f5;
                    box-shadow: 0 0 10px rgba(0,0,0,.28);
                    transition: visibility 0s linear 0s, opacity 0s linear, margin .5s ease;
                    .region-ultimenu-main-495 {
                      
                      .block__content {

                        >.menu {

                          // display: grid;
                          // grid-template-columns: 2fr, 1fr, 1fr;
                          // grid-gap: 1rem;
                          display: flex;
                          justify-content: space-between;
                          li {
                            
                            a {

                              padding: 0;
                              &:hover {

                                color: $brand-primary;
                                background: none;
                              }
                              .caret {

                                display: none;
                              }
                              &.active {

                                &:after {

                                  display: none;
                                }
                              }
                            }
                          }
                          >li {

                            width: 32%;
                            >a {

                              margin-bottom: 2rem;
                              font-weight: 700;
                              font-size: 18px;
                              color: #222;
                              padding-left: 1rem;
                              border-bottom: solid 1px rgba(34, 34, 34, 0.2);
                              padding-bottom: 1rem;
                              display: flex;
                              align-items: center;
                              transition: all 0.3s ease-in-out;
                              padding: 1rem 0;
                              &:before {
  
                                content: "\f10a";
                                font-family: icons;
                                font-size: 3rem;
                                margin-right: 1rem;
                                display: none;
                              }
                              &:hover {
  
                                color: $brand-primary;
                              }
                            }
                            ul {

                              li {

                                a {

                                  font-weight: 400;
                                  font-size: 15px;
                                  padding: 0.7rem 0;
                                  &:hover {

                                    padding-left: 1rem;
                                    background-color: #e9e9e9;
                                    color: $brand-secondary;
                                  }
                                  &.active {

                                    border: none;
                                    color: $brand-primary;
                                  }
                                }
                              }

                              &:before,
                              &:before {  

                                content: unset !important;
                              } 
                            }
                          }
                          .menu-link-insurance {

                            // order: 1;
                            // grid-row-start: 1;
                            // grid-row-end: 3;
                            >ul {

                              // display: grid;
                              // grid-template-columns: repeat(2, 1fr);
                              // grid-gap: 1rem;

                            }
                          }
                          .menu-link-group-benefits {

                            order: 2;
                            grid-row-start: 2;
                            grid-row-end: 3;
                            >a {

                              &:before {

                                content: "\f10b";
                              }
                            }
                          }
                          .menu-link-investment {

                            order: 2;
                            grid-row-start: 1;
                            grid-row-end: 2;
                            >a {

                              &:before {

                                content: "\f10c";
                              }
                            }
                          }
                        }
                      }
                    }
                    a {

                      color: $brand-secondary;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.stick-top {

      position: fixed;
      top: 0;
    }
  }
}

// headroom
.headroom {

  will-change: transform;
  transition: all 200ms linear;
  background-color: rgba(0,0,0,.2);
}

.headroom--unpinned {

  transform: translateY(-100%);
}

.headroom--not-top {

  background-color: $white;
  box-shadow: 0 0 5px #e2e2e2;
  &.headroom--pinned {

    .block-topbar--phone, .block-topbar--location {

      a {

        color: $black !important;
        &:hover {

          color: $black !important;
        }
      }
    }
  }

  .block-topbar--phone, .block-topbar--location {

    a {

      color: $black !important;
      &:hover {

        color: $black !important;
      }
    }
  }

  .block-topbar {

    color: $black !important;
    .basic__field-link, .basic__body {

      a {

        color: $black !important;
        border-color: $brand-primary !important;
        text-decoration: none;
        &:hover {

          color: $black !important;
        }
      }
    }
    
    &.block-topbar--location {

      background-image: url('../images/icon-location-red.svg');
    }

    &.block-topbar--phone {

      background-image: url('../images/icon-phone-red.svg');
    }
  }

  .nav-logo {

    svg {

      .st0 {

        fill: #4D4D4D !important;
      }
    }
  }
}

.headroom--top {

  .block-topbar--location {

    a {

      color: $white;
      &:hover {
        
        color: $white;
      }
    }
  }
}