// icon8 font
// -----------------------------------------------------------------------------

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "icons";
    src: url("../fonts/icons.svg#icons") format("svg");
  }
}

@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format("embedded-opentype"),
       url("../fonts/icons.woff2") format("woff2"),
       url("../fonts/icons.woff") format("woff"),
       url("../fonts/icons.ttf") format("truetype"),
       url("../fonts/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "icons";
    src: url("../fonts/icons.svg#icons") format("svg");
  }
}

[data-icons8]:before { content: attr(data-icons8); }

.icons8, [data-icons8]:before,
.icons8-around-the-globe:before,
.icons8-autograph:before,
.icons8-blog:before,
.icons8-blog-2:before,
.icons8-brain:before,
.icons8-breaking-news:before,
.icons8-business-person:before,
.icons8-clew:before,
.icons8-connectivity-and-help:before,
.icons8-doctor-male:before,
.icons8-education:before,
.icons8-education-2:before,
.icons8-education-3:before,
.icons8-education-4:before,
.icons8-education-5:before,
.icons8-education-6:before,
.icons8-email-envelope:before,
.icons8-envelope:before,
.icons8-facebook-logo:before,
.icons8-family:before,
.icons8-family-two-women:before,
.icons8-fingerprint:before,
.icons8-future:before,
.icons8-goal:before,
.icons8-home:before,
.icons8-home-page:before,
.icons8-home-page-2:before,
.icons8-house:before,
.icons8-human-head:before,
.icons8-instagram:before,
.icons8-iphone-x:before,
.icons8-iphone-x-2:before,
.icons8-life-cycle:before,
.icons8-linkedin-2:before,
.icons8-money:before,
.icons8-number-pad:before,
.icons8-phone:before,
.icons8-place-marker:before,
.icons8-primary-education:before,
.icons8-quote-left:before,
.icons8-right-pointing-arrow:before,
.icons8-save-money:before,
.icons8-small-house-with-terrace:before,
.icons8-social-group:before,
.icons8-social-group-2:before,
.icons8-thumbs-up:before,
.icons8-tick-box:before,
.icons8-twitter:before,
.icons8-user-male:before,
.icons8-youtube-play-button-logo:before {
  display: inline-block;
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icons8-around-the-globe:before { content: "\f106"; }
.icons8-autograph:before { content: "\f107"; }
.icons8-blog:before { content: "\f111"; }
.icons8-blog-2:before { content: "\f112"; }
.icons8-brain:before { content: "\f128"; }
.icons8-breaking-news:before { content: "\f11b"; }
.icons8-business-person:before { content: "\f132"; }
.icons8-clew:before { content: "\f109"; }
.icons8-connectivity-and-help:before { content: "\f108"; }
.icons8-doctor-male:before { content: "\f131"; }
.icons8-education:before { content: "\f10f"; }
.icons8-education-2:before { content: "\f115"; }
.icons8-education-3:before { content: "\f122"; }
.icons8-education-4:before { content: "\f123"; }
.icons8-education-5:before { content: "\f124"; }
.icons8-education-6:before { content: "\f125"; }
.icons8-email-envelope:before { content: "\f11a"; }
.icons8-envelope:before { content: "\f100"; }
.icons8-facebook-logo:before { content: "\f103"; }
.icons8-family:before { content: "\f126"; }
.icons8-family-two-women:before { content: "\f127"; }
.icons8-fingerprint:before { content: "\f10d"; }
.icons8-future:before { content: "\f129"; }
.icons8-goal:before { content: "\f114"; }
.icons8-home:before { content: "\f11f"; }
.icons8-home-page:before { content: "\f11d"; }
.icons8-home-page-2:before { content: "\f11e"; }
.icons8-house:before { content: "\f121"; }
.icons8-human-head:before { content: "\f10a"; }
.icons8-instagram:before { content: "\f105"; }
.icons8-iphone-x:before { content: "\f117"; }
.icons8-iphone-x-2:before { content: "\f118"; }
.icons8-life-cycle:before { content: "\f12b"; }
.icons8-linkedin-2:before { content: "\f11c"; }
.icons8-money:before { content: "\f10c"; }
.icons8-number-pad:before { content: "\f119"; }
.icons8-phone:before { content: "\f101"; }
.icons8-place-marker:before { content: "\f116"; }
.icons8-primary-education:before { content: "\f10e"; }
.icons8-quote-left:before { content: "\f113"; }
.icons8-right-pointing-arrow:before { content: "\f110"; }
.icons8-save-money:before { content: "\f12a"; }
.icons8-small-house-with-terrace:before { content: "\f120"; }
.icons8-social-group:before { content: "\f10b"; }
.icons8-social-group-2:before { content: "\f134"; }
.icons8-thumbs-up:before { content: "\f12f"; }
.icons8-tick-box:before { content: "\f12d"; }
.icons8-twitter:before { content: "\f102"; }
.icons8-user-male:before { content: "\f135"; }
.icons8-youtube-play-button-logo:before { content: "\f104"; }