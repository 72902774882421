// Styles for blocks.
// -----------------------------------------------------------------------------

// front page slideshow
// -----------------------------------
  .slideshow {
    background-color: $white;
    padding-bottom: 6rem;

    .views-slideshow-cycle-processed {
      height: calc(100vh - 195px);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .views_slideshow_main {
      width: 100%;
      height: 100% !important;

      .views-slideshow-cycle-main-frame {
        width: 100% !important;
        height: 100% !important;

        .views-slideshow-cycle-main-frame-row {
          width: 100% !important;
          height: 100% !important;

          .views-slideshow-cycle-main-frame-row-item {
            height: 100% !important;
          }
        }
      }
    }

    .views-row {
      display: flex;
      background-color: #222;
    }
    
    .content {
      position: absolute;
      top: 20%;
      left: 15%;
      z-index: 9;
      background-color: rgba(0,0,0,0.2);
      padding: 2rem;
    }

    .views-field-title {
      max-width: 600px;
      margin-bottom: 2rem;

      .field-content {
        color: $white;
        text-transform: uppercase;
        letter-spacing: -2.5px;
        line-height: 50px;
        font-weight: 600;
        font-size: 50px;
        text-shadow: 3px 3px black;
      }
    }

    .views-field-field-description {
      max-width: 500px;
      margin-bottom: 5rem;

      .field-content {
        color: $white;
        font-weight: 300;
        line-height: 28px;
        font-size: 20px;
        text-shadow: 2px 2px black;
      }
    }

    .views-field-field-link {
      margin-bottom: 2rem;

      a {
        color: $white;
        padding: 24px 31px;
        border: solid 2px $brand-primary;
        background-color: $brand-primary;
        text-transform: uppercase;
        letter-spacing: 2px;
        border-radius: 40px;
        font-weight: 400;

        &:hover {
          border-color: $brand-primary; 
          background-color: transparent;
        }
      }
    }

    .views-field-field-image {

      .field-content {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    
    .views-slideshow-controls-bottom {
      position: absolute;
      bottom: -7rem;
      z-index: 9;
      padding: 0;

      .views-slideshow-pager-fields {
        display: flex;

        .views-slideshow-pager-field-item {
          padding: 0;
          flex: 1;
          display: flex;
          align-items: center;
          position: relative;
          .views-field-nothing {
            width: 100%;
          }

          a {
            border-right: 1px solid rgba(230,230,230,.25);
            flex-basis: 1;
            padding: 4rem 0 4rem 7rem;
            border-color: #e6e6e6;
            background-color: $white;
            box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.13);
            display: flex;
            flex-direction: column;
            min-height: 190px;
            div {

              &.title {
                display: block;
                letter-spacing: 1px;
                margin-bottom: 18px;
                line-height: 18px;
                font-weight: 600;
                font-size: 11px;
                color: $brand-primary;
                text-transform: uppercase;
              }

              &.subtitle {
                line-height: 22px;
                font-size: 16px;
                font-weight: 300;
                color: #444;
              }
            }

            &:focus {
              text-decoration: none;
            }
          }

          &:before {
            content: "\f12f";
            font-family: icons;
            color: $brand-secondary;
            font-size: 4em;
            position: absolute;
            margin-left: 1rem;
            transition: all 0.3s ease-in-out;
            z-index: 9999;
          }

          &:nth-child(2) {
            
            &:before {
              content: "\f12a";
            }
          }

          &:nth-child(3) {
  
            &:before {
              content: "\f10c";
            }
          }

          &:nth-child(4) {
            
            &:before {
              content: "\f12b";
            }
          }

          &.active {

            a {
              background-color: $brand-primary; 
              border: none;

              div {

                &.title,
                &.subtitle {
                  color: $white;
                }
              }

              &:hover, 
              &:focus {
                text-decoration: none !important;
              }
            }
            
            &:before {
              color: $white;
            }

            &:hover { 
              text-decoration: none !important;

              div, a {
                text-decoration: none !important;
              }
            }
          }    
        }
      }
    }

    .top-space {
      margin-top: 80px;
    }
  }
// -----------------------------------

// general blocks styles
// -----------------------------------
  .front-page-blocks {

    .block__content {
      
      h2 {
        font-size: 3.5rem;
        line-height: 3.8rem;
        font-weight: 200;
        letter-spacing: -1.7px;
        margin-bottom: 2rem;
      }
    }

    &.right-line {

      .block__title {
        display: flex;
        align-items: center; 
        font-size: 1.4rem;
        line-height: 1em;
        font-weight: 600;
        letter-spacing: 4px;
        color: $brand-primary;
        text-transform: uppercase;
        padding: 0 0.8rem 0 0;

        &:after {
          content: "";
          height: 2px;
          width: 40px;
          background-color: #aaa;
          margin-left: 0.5rem;
        }
      }
    }

    &.title-lines {

      .block__title {  
        display: flex;
        align-items: center; 
        justify-content: center;
        font-size: 1.4rem;
        line-height: 1em;
        font-weight: 600;
        letter-spacing: 4px;
        color: $brand-primary;
        text-transform: uppercase;
        padding: 0 0.8rem 0 0;
        
        &:after {
          content: "";
          height: 2px;
          width: 40px;
          background-color: #aaa;
          margin-left: 1rem;
        }

        &:before {
          content: "";
          height: 2px;
          width: 40px;
          background-color: #aaa;
          margin-right: 1rem;
        }
      }
    }

    &.blue-title {

      .block__title {
        color: $brand-secondary;
      }
    }
  }
// -----------------------------------

// front page blocks
// -----------------------------------

  // front page about
  // -----------------------------------
    .front-page-blocks.about-us {

      .round-button.white-text.blue-border {
        color: $brand-secondary;

        &:hover {
          color: $white;
        }
      }
    }

    .about-images-parallax {

      .block__content {
        display: flex;
        justify-content: flex-end;
        position: relative;
      }

      .parallax-image-2 {
        position: absolute;
        top: -4rem;
        left: 3rem;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img {
          transition: all 0.3s ease-in-out;
        }

        i {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 9;
          font-size: 6rem;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          color: #ff0000;
          
          &:before {
            z-index: 99;
          }

          &:after {
            content: "";
            background-color: $white;
            width: 30%;
            height: 30%;
            position: absolute;
            z-index: 1;
          }

        }
        
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          background-color: #222;
          opacity: 0;
          position: absolute;
          top: 0;
          right: 0;
          transition: all 0.3s ease-in-out;
        }

        &:hover {

          img {
            filter: grayscale(0.9);
          }
          
          i {
            color: #ff0000;
          }

          &:after {
            opacity: 0;
          }

        }
      }
    }
  // -----------------------------------


  // front page categories
  // -----------------------------------
    .front-page-blocks.categories { 
      max-width: 60%;
      margin-left: auto;
      padding: 3rem 0;

      .content {
        display: flex;
        flex-wrap: wrap;
      }

      .categories-blocks {
        flex-basis: 50%;
        margin-top: 4rem;
        padding: 0 1rem;

        .icon {
          color: $brand-secondary;
          font-size: 3.5rem;
        }

        .title {
          display: inline-flex;
          font-size: 30px;
          line-height: 40px;
          letter-spacing: -1.5px;
          font-weight: 300;
          padding-bottom: 0.4rem;
          margin-bottom: 1rem;
          border-bottom: solid 2px $brand-secondary;
        }

        .text {
          margin-bottom: 25px;
          line-height: 20px;
          font-size: 16px;
          font-weight: 300;
          line-height: 1.3;
          font-family: 'Open Sanse';
          color: $gray;
        }

        .link {

          a {
            font-size: 15px;
            font-family: $font-family-secondary;
            line-height: 1.714em;
            display: flex;
            align-items: center;
            font-weight: 700;
            margin-left: -2rem;
            color: $brand-primary;

            &:before {
              content: "\f110";
              font-family: icons;
              font-size: 2rem;
              position: relative;
              top: 1px;
              transition: all 0.15s ease 0s;
              margin-right: 0.4rem;
              opacity: 0;
            }

            &:hover {
              margin-left: 0.2rem;
              color: $brand-primary;

              &:after {
                opacity: 0;
              }

              &:before {
                opacity: 1;
              }
            }
          }
        }

        &.categories-1 {
          flex-basis: 100%;
        }
      }
    }
  // -----------------------------------
  

  // front page services 
  // -----------------------------------
    .front-page-services {
      padding: 2rem 0;

      .block__content {
        position: relative;
      }

      &.services-mobile {
        display: none;

        .view-content {
          margin-left: auto;
          .views-row {
            display: flex;
            flex-direction: column;
            background-color: $white;
            box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.08);
            border: solid 1px rgba(170, 170, 170, 0.21);
            margin-bottom: 2rem;

            .views-field-field-image {
              img {
                width: 100%;
                height: auto;
              }
            }

            .views-field-name {
              margin: 2rem 0;
              padding: 0 1rem;

              a {
                color: $text-color;
                letter-spacing: -1px;
                line-height: 22px;
                font-weight: 500;
                font-size: 18px;
              }
            }

            .views-field-description {
              padding: 0 1rem;
              
              p {
                font-family: $font-family-secondary;
                color: $text-color;
                font-size: 14px;
                font-weight: 400;
              }
            }

            .views-field-name-1 {
              padding: 0 1rem;
              margin-top: auto;
              margin-bottom: 1rem;

              a {
                font-size: 15px;
                font-family: $font-family-secondary;
                line-height: 1.714em;
                display: flex;
                align-items: center;
                margin-left: -2rem;
                color: $brand-primary;
                &:after {

                  content: "\f110";
                  font-family: icons;
                  font-size: 2rem;
                  position: relative;
                  top: 1px;
                  transition: all 0.15s ease 0s;
                  margin-left: 0.4rem;
                  opacity: 1;
                }
                &:before {

                  content: "\f110";
                  font-family: icons;
                  font-size: 2rem;
                  position: relative;
                  top: 1px;
                  transition: all 0.15s ease 0s;
                  margin-right: 0.4rem;
                  opacity: 0;
                }
                &:hover {

                  margin-left: 1rem;
                  color: $brand-secondary;
                  &:after {

                    opacity: 0;
                  }
                  &:before {

                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }

      .view-header {
        margin: 0 auto;
        max-width: 800px;
        margin-bottom: 3rem;

        p {
          text-align: center;
          font-size: 3.2rem;
          font-family: $font-family-base;
          font-weight: 400;
          line-height: 1.6;
          margin-bottom: 2rem;
        }
      }

      #quicktabs-services {

        .item-list {
          position: absolute;
          z-index: 9;
          bottom: 2rem;
          height: 420px;
          width: 26%;

          .quicktabs-tabs {
            display: flex;
            flex-direction: column; 
            height: 100%;

            li {
              display: flex;
              flex: 1;
              margin: 0;
              padding: 0;

              a {  
                font-size: 2rem;
                font-weight: bold;
                color: $black;
                width: 100%;
                height: 100%;
                transition: all 0.3s cubic-bezier(.17,.67,.07,1);
                background-color: #f5f5f5;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                &:focus {

                  text-decoration: none;
                }
                &:after {

                  content: "\f135";
                  font-family: icons;
                  font-size: 10rem;
                  position: absolute;
                  transition: all 0.3s ease-in-out;
                  color: transparent;
                }
              }

              &.active {

                a {
                  color: $white;
                  background-color: $brand-primary;
                  border-color: $brand-primary;

                  &:after {
                    color: rgba(255, 255, 255, 0.38);
                  }
                }
              }

              &:nth-child(2) {

                a {

                  &:after {

                    content: "\f10c";
                  }
                }
              }

              &:nth-child(3) {

                a {

                  &:after {

                    content: "\f134";
                  }
                }
              }
              &:nth-child(4) {

                display: none;
                a {

                  &:after {

                    content: "\f134";
                  }
                }
              }
            }
          }
        }
        .quicktabs-tabpage {

          transition: all 0.3s cubic-bezier(.17,.67,.07,1);
        }
      }

      .quicktabs-tabpage {
        
        .view {

          position: relative;
          .view-content {

            max-width: 830px;
            margin-left: auto;
            position: relative;
            max-height: 420px;
            overflow: hidden;
            .services-catagories {

              display: flex;
              flex-wrap: wrap;
              width: 100%;
              .views-row {
  
                max-height: 420px;
                flex-basis: 32%;
                max-width: 263.33px;
                display: flex;
                flex-direction: column;
                background-color: $white;
                // box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.08);
                border: solid 1px rgba(170, 170, 170, 0.21);
                img {
  
                  width: 100%;
                  height: auto;
                }
                .views-field-title {
  
                  margin: 2rem 0;
                  padding: 0 1rem;
                  a {
  
                    color: #222;
                    letter-spacing: -1px;
                    line-height: 22px;
                    font-weight: 500;
                    font-size: 18px;
                  }
                }
                .views-field-body {
  
                  padding: 0 1rem;
                  margin-bottom: 2rem;
                  p {
  
                    font-family: $font-family-secondary;
                    color: #222;
                    font-size: 14px;
                    font-weight: 400;
                    margin: 0;
                  }
                }
                .views-field-view-node {
  
                  padding: 0 1rem;
                  padding-bottom: 2rem;
                  margin-top: auto;
                  margin-bottom: 1rem;
                  a {
  
                    font-size: 15px;
                    font-family: $font-family-secondary;
                    line-height: 1.714em;
                    display: flex;
                    align-items: center;
                    margin-left: -2rem;
                    color: $brand-primary;
                    transition: all 0.15s ease-in-out;
                    &:after {
  
                      content: "\f110";
                      font-family: icons;
                      font-size: 2rem;
                      position: relative;
                      top: 1px;
                      transition: all 0.15s ease 0s;
                      margin-left: 0.4rem;
                      opacity: 1;
                    }
                    &:before {
  
                      content: "\f110";
                      font-family: icons;
                      font-size: 2rem;
                      position: relative;
                      top: 1px;
                      transition: all 0.15s ease 0s;
                      margin-right: 0.4rem;
                      opacity: 0;
                    }
                    &:hover {
  
                      margin-left: 1rem;
                      color: $brand-secondary;
                      &:after {
  
                        opacity: 0;
                      }
                      &:before {
  
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
            .owl-carousel {

              .owl-stage-outer {

                // padding: 1rem 0;
                .owl-stage {

                  .owl-item {
                    
                    >div {

                      min-height: 420px;
                      display: flex;
                      flex-direction: column;
                      background-color: $white;
                      // box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.08);
                      border: solid 1px rgba(170, 170, 170, 0.21);
                      .views-field-title {

                        margin: 2rem 0;
                        padding: 0 1rem;
                        a {

                          color: #222;
                          letter-spacing: -1px;
                          line-height: 22px;
                          font-weight: 500;
                          font-size: 18px;
                        }
                      }
                      .views-field-body {
                        padding: 0 1rem;
                        p {

                          font-family: $font-family-secondary;
                          color: #222;
                          font-size: 14px;
                          font-weight: 400;
                        }
                      }
                      .views-field-view-node {
                        padding: 0 1rem;
                        margin-top: auto;
                        margin-bottom: 1rem;
                        a {

                          font-size: 15px;
                          font-family: $font-family-secondary;
                          line-height: 1.714em;
                          display: flex;
                          align-items: center;
                          margin-left: -2rem;
                          color: $brand-primary;
                          &:after {

                            content: "\f110";
                            font-family: icons;
                            font-size: 2rem;
                            position: relative;
                            top: 1px;
                            transition: all 0.15s ease 0s;
                            margin-left: 0.4rem;
                            opacity: 1;
                          }
                          &:before {

                            content: "\f110";
                            font-family: icons;
                            font-size: 2rem;
                            position: relative;
                            top: 1px;
                            transition: all 0.15s ease 0s;
                            margin-right: 0.4rem;
                            opacity: 0;
                          }
                          &:hover {

                            margin-left: 1rem;
                            color: $brand-secondary;
                            &:after {

                              opacity: 0;
                            }
                            &:before {

                              opacity: 1;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .owl-dots {
                
                display: none;
                position: absolute;
                left: -21rem;
                bottom: 11rem;
                .owl-dot {

                  span {

                    transition: all 0.3s ease-in-out;
                    width: 10px;
                    height: 10px;
                  }
                  &.active {

                    span {

                      background: $brand-primary ;
                      width: 20px;
                    }
                  }
                  &:hover {

                    span {

                      background: $brand-primary ;
                    }
                  }
                }
              }
            }
            .preloader {

              width: 100%;
              height: 425px;
              position: absolute;
              top: 0px;
            }
          }
          .view-footer {

            max-width: 260px;
            position: absolute;
            left: 0;
            top: 15rem;
            display: none;
            h2 {

              font-size: 35px;
              line-height: 40px;
              font-weight: 600;
              letter-spacing: -2px;
              margin: -3px 0 30px;
              color: #23282d;
              font-weight: 500;
            }
            p {

              font-size: 14px;
              line-height: 30px;
              color: #222222;
              
            }
          }
        }
      }
    }

  // -----------------------------------
  
  // front page solutions
  // -----------------------------------
    .front-page-solution {

      margin-left: auto;
      padding-bottom: 3rem;
      .block__content {

        margin-top: 1rem;
        .view-header {
  
          margin: 3rem 0;
          h2 {

            text-align: center;
            color: #23282d;
            font-size: 35px;
            font-family: 'Ubuntu';
            font-weight: 400;
            line-height: 42px;
            margin-bottom: 24px;
            font-variant: small-caps;
            letter-spacing: 1px;
          }
        }
        .view-content {

          .views-slideshow-cycle-processed {

            display: flex;
            align-items: center;
            height: 350px;
            box-shadow: 12px 12px 17px rgba(0,0,0,0.08);
            .views-slideshow-controls-top {

              height: 100%;
              .widget_pager_top {

                height: 100%;
                display: flex;
                flex-direction: column;
                .views-slideshow-pager-field-item {
                  flex: 1;
                }
              }
              .views-slideshow-pager-fields {
  
                display: flex;
                flex-direction: column;
                .views-slideshow-pager-field-item {
  
                  display: flex;
                  width: 100%;
                  .views-field-title {
                    
                    display: flex;
                    width: 100%;
                    .views-content-title {
                      
                      display: flex;
                      width: 100%;
                      position: relative;
                      justify-content: center;
                      a {
                    
                        font-size: 1.7rem;
                        font-weight: bold;
                        color: $black;
                        padding: 0.7rem 2rem;
                        width: 100%;
                        // transition: all 0.3s cubic-bezier(.17,.67,.07,1);
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        // overflow: hidden;
                        background-color: $white;
                        text-align: center;

                        &:focus {
      
                          text-decoration: none;
                        }
                        // &:before {

                        //   content: '';
                        //   width: 3px;
                        //   height: 100%;
                        //   position: absolute;
                        //   left: 0px;
                        //   top: 0;
                        //   background-color: transparent;
                        //   z-index: 99;
                        // }
                        // &:after {

                        //   content: '';
                        //   width: 3px;
                        //   height: 100%;
                        //   position: absolute;
                        //   left: 0px;
                        //   top: 0;
                        //   background-color: rgba(220, 223, 229, 0.48);
                        // }
                      }
                      &:after {

                        content: "\f131";
                        font-family: icons;
                        font-size: 9rem;
                        position: absolute;
                        // bottom: 20%;
                        transition: all 0.3s ease-in-out;
                        color: rgba(255, 255, 255, 0.08);
                      }
                    }
                  }
                  &.active {

                    text-decoration: none;
                    .views-field-title {

                      text-decoration: none;
                      .views-content-title { 
  
                        a {
                          
                          color: $white;
                          text-decoration: none;
                          background-color: $brand-primary;
                          &:before {

                            background-color: $brand-primary;
                            animation: border-fill 5s linear forwards;
                          }
                        }
                        &:after {

                          color: rgba(255, 255, 255, 0.4);
                        }
                      }
                    }
                  }
                  &:nth-child(2) {

                    .views-field-title {

                      .views-content-title {

                        &:after {

                          content: "\f132";
                        }
                      }
                    }
                  }
                  &:nth-child(3) {

                    .views-field-title {

                      .views-content-title {

                        &:after {

                          content: "\f126";
                        }
                      }
                    }
                  }
                }
              }
            }
            .views_slideshow_cycle_main {

              margin-left: auto !important;
              background-color: $white;
              flex: 1;
              border: solid 1px rgba(170,170,170,0.21);
              .views-slideshow-cycle-main-frame {

                width: 100% !important;
                height: 350px !important;

                .views-slideshow-cycle-main-frame-row {
                  
                  width: 100% !important;
                  height: 350px !important;
                  .views-row {
                    
                    display: flex;
                    flex-direction: row-reverse;
                    width: 100%;
                    .right {
  
                      flex: 1;
                      width: 100%;
                      padding: 3rem;
                      padding-left: 4rem;
                      display: flex;
                      flex-direction: column;
                      position: relative;
                      .views-field-body {
      
                        width: 100%;
                        position: relative;
                        .field-content {
        
                          p {
        
                            margin: 0 0 10px;
                            font-family: $font-family-base;
                            font-weight: bold;
                            display: flex;
                            align-items: center;
                            position: relative;
                            &:before {

                              content: "•";
                              font-size: 4rem;
                              color: rgb(239, 7, 0);
                              line-height: 0; 
                              left: -1.9rem;
                              margin-right: 0.5rem;
                              position: absolute;
                            }
                            &:after {

                              content: "•";
                              font-size: 2rem;
                              color: $white;
                              line-height: 0; 
                              left: -1.58rem;
                              margin-right: 0.5rem;
                              position: absolute;
                            }
                          }
                        }
                      }
                      .views-field-view-node {

                        align-self: flex-end;
                        margin-top: auto;
                        margin-right: 2rem;

                        a {

                          font-size: 15px;
                          font-weight: bold;
                          line-height: 1.714em;
                          display: flex;
                          align-items: center;
                          margin-right: -2rem;
                          color: $brand-primary;
                          &:hover {

                            color: $brand-secondary;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  // -----------------------------------

  // front page video background
  // -----------------------------------
    .front-page-separator {
      position: relative;
      z-index: 2;
      color: $white;

      .block__title {
        font-weight: 300;
        font-size: 5rem;
        text-align: center;
      }

      .block__content {
        text-align: center;
        margin-top: 3rem; 

        p {
          color: $white;
          margin-top: 1rem;
          text-transform: none;
          font-weight: 300;
          font-size: 1.9rem;
          line-height: 1.6;
        }

        .links {
          margin-top: 3rem;

          a {  
            margin: 0 0.5rem;
          }
        }
      }
    }
  // -----------------------------------

  // front page blog
  // -----------------------------------
    .front-page-blog {

      padding: 5rem 0;
      .block__title {
        margin-bottom: 3rem;
      }

      .block__content {

        .view-content {

          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .views-row {

            perspective: 1000px;
            max-width: 31%;

            .image-title {

              position: relative;
              z-index: 9;
              width: 100%;
              height: 100%;
              text-align: center;
              transition-property: transform, -webkit-transform;
              transition-duration: 0.6s, 0.6s;
              transition-timing-function: ease, ease;
              transition-delay: 0s, 0s;
              transform-style: preserve-3d;
              backface-visibility: hidden;
              .views-field-field-image {
                
                background-color: #444;
                width: 100%;
                box-shadow: 0px 10px 30px 0px rgba(0,0,0,.2);
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .field-content {

                  width: 100%;
                  img {

                    width: 100%;
                  }
                }
              }
            }
            .body-link {

              overflow: hidden;
              position: absolute;
              z-index: 1;
              top: 0;
              width: 100%;
              height: 350px;
              background-color: $brand-primary;
              transform-style: preserve-3d;
              transition-property: transform, -webkit-transform;
              transition-duration: 0.6s, 0.6s;
              transition-timing-function: ease, ease;
              transition-delay: 0s, 0s;
              backface-visibility: hidden;
              transform: rotateY(180deg);
              padding: 2rem;
              .views-field-body {

                backface-visibility: visible;
                transform: translateX(-100px);
                transition-property: transform, rotateY, -webkit-transform;
                transition-duration: 0.5s, 0.5s;
                transition-timing-function: ease, ease;
                transition-delay: 0.15s, 0.15s;
                text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.51);
                p {

                  color: $white;
                  font-weight: 300;
                  font-size: 1.7rem;
                  line-height: 1.5;
                }
              }
              .views-field-view-node {

                transform: translateX(-100px);
                transition-property: transform, -webkit-transform;
                transition-duration: 0.5s, 0.5s;
                transition-timing-function: ease, ease;
                transition-delay: 0.15s, 0.15s;
                text-align: center;
                backface-visibility: visible;
                text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.51);
                .round-button {

                  border-color: $white;
                  background-color: $white;
                  transition: all 0.3s ease-in-out;
                  a {
                    
                    color: $brand-primary;
                  }
                  &:hover {

                    background-color: $brand-primary;
                    a {

                      color: $white;
                    }
                  }
                }
              }
              .views-field-nothing {

                font-size: 15rem;
                color: $white;
                opacity: 0;
                transition-duration: 0.5s, 0.5s;
                transition-timing-function: ease-in-out, ease-in-out;
                transition-delay: 0.3s, 0.3s;
                position: absolute;
                right: -4rem;
                top: 40%;
                transform: translateX(10px);
              }
            }
            .views-field-title {

              padding: 0 1rem;
              text-align: center;
              font-size: 2rem;
              font-weight: 500;
              a {

                color: $gray;
              }
            }
            &:hover {

              .image-title {

                transform: rotateY(180deg);
              }
              .body-link {

                transform: rotateY(360deg);
                .views-field-body {

                  transform: translateX(0px);
                }
                .views-field-view-node {

                  transform: translateX(0px);
                  text-shadow: none;
                }
                .views-field-nothing {

                  opacity: 0.1;
                  transform: translateX(0px);
                }
              }
            }
          }
          .owl-carousel {
            
            .owl-stage-outer {

              padding: 0 1rem;
              .owl-stage {
                
                padding: 4rem 0; 
                .owl-item {
                  max-width: 366px;
                  >div {

                   
                  }
                }
              }
            }
            .owl-dots {

              .owl-dot {

                span {

                  transition: all 0.3s ease-in-out;
                  width: 10px;
                  height: 10px;
                }
                &.active {

                  span {

                    background: $brand-primary;
                    width: 20px;
                  }
                }
                &:hover {

                  span {

                    background: $brand-primary;
                  }
                }
              }
            }
          }
        }
      }
    }
  // -----------------------------------
    
  // front page testimonials
  // -----------------------------------
    .front-page-testimonials {
      padding: 4rem;

      .views-field-body {

        p {

          margin-bottom: 40px;
          line-height: 30px;
          font-weight: 300;
          font-size: 18px;
          color: $white;
        }
        &:before {

          content: "\f113";
          font-family: icons;
          font-size: 7rem;
          color: $brand-primary;
        }
      }

      .bottom {
        display: flex;
        flex-direction: column;
        padding-left: 10rem;
        position: relative;

        &:before {

          content: '';
          width: 9rem;
          height: 2px;
          background-color: $white;
          position: absolute;
          left: 0rem;
          margin-top: 0.5rem;
        }
      }

      .views-field-title {

        letter-spacing: 3px;
        margin-bottom: 5px;
        line-height: 16px;
        font-weight: 600;
        font-size: 11px;
        color: #fff;
        text-transform: uppercase;
      }

      .views-field-field-position {

        font-size: 14px;
        color: $brand-primary;
      }

      .owl-dots {

        .owl-dot {

          span {

            transition: all 0.3s ease-in-out;
            width: 10px;
            height: 10px;
            background: #9a9a9a;
          }
          &.active {

            span {

              background: $brand-primary;
              width: 20px;
            }
          }
          &:hover {

            span {

              background: $brand-primary;
            }
          }
        }
      }
    }
  // -----------------------------------
  
  // front page request a quote webforme
  // -----------------------------------
    .request-quote-webforme {

      .col-md-6 {

        padding-left: 0;
        &:nth-child(1),
        &:nth-child(3) {

          padding-right: 0;
        }

      }
      .col-md-12 {

        padding: 0;
      }
      input,
      textarea {

        border: none;
        border-radius: 0;
        box-shadow: none;
        min-height: 45px;
      }
      select {

        border: none;
        border-radius: 0;
        box-shadow: none;
        height: 45px;
        color: #999999;
      }
      .grippie {

        display: none;
      }
      .captcha {

        display: inline-block;
        float: left;
      }
      .form-actions {

        display: inline-block;
        float: right;
        input {

          font-size: 12px;
          color: $white;
          padding: 18px 23px;
          border: solid 2px $brand-primary;
          text-transform: uppercase;
          letter-spacing: 2px;
          border-radius: 40px;
          font-weight: 600;
          display: inline-flex;
          // margin-top: 2rem;
          transition: all 0.3s ease-in-out;
          &:hover {
      
            background-color: transparent; 
            color: $gray;
          }
        }
      }
    }
  // -----------------------------------

  // front page request a quote text
  // -----------------------------------
    .request-quote {

      min-width: 500px;
      margin-top: 2rem;
      padding-right: 12rem;
      .block__content {

        h2 {

          font-size: 35px;
          line-height: 40px;
          text-align: left;
          font-family: 'Montserrat';
          color: $brand-secondary;
          font-weight: 600;
        }
        p {

          font-size: 18px;
          font-weight: 100;
          line-height: 30px;
          font-family: $font-family-secondary;
          color: $brand-secondary;
          color: #222;
        }
      }
    }
  // -----------------------------------

  // front page partners
  // -----------------------------------
    .block--partners {

      .block__content {

        .view-content {

          .owl-stage-outer {

            .owl-stage {

              .owl-item {

                height: 100px;
                >div {

                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  // -----------------------------------

// -----------------------------------

// footer
// -----------------------------------

  .footer-blocks {

    flex-basis: 25%;
    .block__title {
      
      letter-spacing: -1px;
      font-size: 20px;
      font-weight: 600;
      color: #222;
      margin-bottom: 27px;
    }
    .block__content {

      padding: 0 1rem;
      p {
        
        line-height: 22px;
        color: #1b1b1b;
        font-family: $font-family-secondary;
        font-size: 14px;
        font-weight: 600;
      }
    }
    &.footer-social-media {

      position: absolute;
      right: 0;
      top: 50%;
      width: 25%;
      z-index: 99;
      .social-media {

        display: flex;
        justify-content: space-between;
        a {

          width: 50px;
          height: 50px;
          line-height: 50px;
          background-color: #f2f3f5;
          margin: 0 5px;
          font-size: 18px;
          color: #888 ;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {

            background-color: $brand-primary;
            color: $white;
          }
        }
      }
    }
    &.footer-services {

      .block__content {

        display: flex;
        flex-direction: column;
        a {

          font-size: 14.5px;
          color: #888 ;
          margin-top: 1.4rem;
          display: flex;
          align-items: center;
          &:before {

            content: "•";
            font-size: 4rem;
            line-height: 0;
            margin-right: 0.7rem;
          }
          &:hover {

            color: $brand-primary;
          }
        }
      }
    }
    &.footer-news-letter {

      .views-field-title {

        display: none;
      }
      input,
      textarea {

        border: none;
        border-radius: 0;
        box-shadow: none;
        min-height: 45px;
        &.email {

          border: solid 1px $brand-primary;
        }
      }
      .webform-client-form {

        >div {

          display: flex;
          .webform-progressbar {

            display: none;
          }
          .form-actions {

            margin: 0;
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
// -----------------------------------

// inner page 
// -----------------------------------

  // about page blocks 
  // ---------------------------------

    .about-page-image-block-top{

      .block__content {
        
        position: relative;
        width: 500px;
        height: 540px;
        img {

          position: relative;
          z-index: 9;
        }
        &:before {

          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: -3rem;
          right: -3rem;
          z-index: 1;
          background-color: #cececf;
        }
      }
    }
    .block--about-farah-jahed {

      padding-left: 7rem;
      .block__title {

        font-size: 34px;
        line-height: 40px;
        text-align: left;
        margin-bottom: 3rem;
      }
 
    }
    .about-certificates  {

      margin: 3rem 0;
      .block__content {

        margin-top: 5rem;
        .view-content {

          .owl-carousel {

            .owl-stage-outer {

              .owl-stage {

                .owl-item {

                  >div {

                    min-height: 350px;
                    display: flex;
                    flex-direction: column;
                    .views-field-field-image {

                      min-height: 300px;
                      display: flex;
                      align-items: center;
                      box-shadow: 3px 2px 11px rgba(0, 0, 0, 0.1215686200);
                      padding: 0 1rem;
                      img {

                        width: 100%;
                        height: auto;
                      }
                    }
                  }
                }
              }
            }
            .owl-dots {

              .owl-dot {

                span {

                  transition: all 0.3s ease-in-out;
                  width: 10px;
                  height: 10px;
                }
                &.active {

                  span {

                    background: $brand-primary ;
                    width: 20px;
                  }
                }
                &:hover {

                  span {

                    background: $brand-primary ;
                  }
                }
              }
            }
          }
        }
      }
    }
  // ---------------------------------
  
  // sidebar 
  // ---------------------------------
    .sidebar-block {

      .block__title {

        letter-spacing: -1px;
        font-size: 20px;
        font-weight: 600;
        color: #23282d;
        margin-bottom: 2rem;
      }
    } 

    .recent-blogs {

      .block__content {

        .view-blog {

          .view-content {

            .views-row {

              display: flex;
              padding-bottom: 2rem;
              margin-bottom: 2rem;
              border-bottom: 1px solid #ccc;
              .views-field-field-image {

                margin-right: 1rem;
                img {

                  width: 58px;
                  height: 58px;
                }
              }
              .right {

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .views-field-title {
  
                  font-weight: 500;
                  a {
  
                    color: $gray;
                  }
                }
                .views-field-field-tags {

                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }

    .sidebar-blog-tags {

      .view-content {

        display: flex;
        flex-wrap: wrap;
        .views-row {

          a {

            display: inline-block;
            vertical-align: top;
            font-size: 13px;
            color: #aaa;
            border: 1px solid #ccc;
            padding: 5px 10px;
            margin: 0 6px 7px 0;
            &:hover {

              color: $white;
              background-color: $brand-primary;
              border-color: $brand-primary;
            }
          }
        }
      }
    }

    .sidebar-subscribe {

      background-color: $brand-secondary;
      padding: 3rem 2rem;
      color: $white;
      margin: 3rem 0;
      text-align: center;
      .block__title {

        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        font-size: 16px;
        .bold {

          font-weight: 700;
          margin-bottom: 0.4rem;
          font-size: 24px;
          letter-spacing: 4px;
        }
        &:before {

          content: "\f11d";
          font-family: icons;
          font-size: 7rem;
          margin-bottom: 2rem;
        }
      }
      .block__content {

        input,
        textarea {

          border: none;
          border-radius: 0;
          box-shadow: none;
          min-height: 45px;
        }
        .captcha {

          .g-recaptcha>div {

            width: 100% !important;
            iframe {

              width: 100%;
            }
          }
        }
        .form-actions {

          input {

            font-size: 12px;
            color: $white;
            padding: 18px 34px;
            border: solid 2px $white;
            text-transform: uppercase;
            letter-spacing: 2px;
            border-radius: 40px;
            font-weight: 600;
            display: inline-flex;
            margin-top: 2rem;
            transition: all 0.3s ease-in-out;
            background-color: $brand-secondary;
            &:hover {
        
              background-color: $white; 
              color: $brand-secondary;
            }
          }
        }
      }
    }

    .sidebar-blog-testimonials {

      border-bottom: 1px solid #ccc;
      padding-bottom: 2rem;
      .block__title {

        color: $brand-primary;
      }
      .block__content {

        .view-content {

          .owl-stage-outer {

            .owl-stage {

              >div {

                .views-field-body {

                  position: relative;
                  background: #eeeff2;
                  padding: 25px 30px 60px;
                  font-size: 16px;
                  line-height: 28px;
                  margin: 0 0 23px;
                  font-weight: 300;
                  &:before {

                    content: "\f113";
                    font-family: icons;
                    font-size: 7rem;
                    color: #d00;
                    position: absolute;
                    right: -2px;
                    bottom: 9px;
                  }
                  &:after {

                    content: '';
                    position: absolute;
                    bottom: -14px;
                    left: 65px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 14px 14px 0 0;
                    border-color: #eeeff2 transparent transparent;
                  }
                }
                .bottom { 

                  margin-right: 1rem;
                  .views-field-title {

                    font-weight: bold;
                    text-transform: uppercase;
                  }
                }
              }
            }
          }
          .owl-dots {

            .owl-dot {
    
              span {
    
                transition: all 0.3s ease-in-out;
                width: 10px;
                height: 10px;
                background: #9a9a9a;
              }
              &.active {
    
                span {
    
                  background: $brand-primary;
                  width: 20px;
                }
              }
              &:hover {
    
                span {
    
                  background: $brand-primary;
                }
              }
            }
          }
        }
      }
    }

    .insurance-life-menu {

      margin-bottom: 2rem;
      .menu {

        li {

          a {

            color: $text-color;
            font-size: 1.6rem;
            font-weight: 700;
            &.active {

              background-color: $brand-primary;
              color: $white;
            }
            .caret {

              display: none;
            }
          }
        }
      }
    }
  // ---------------------------------
  
  // modals
  // ---------------------------------
    .modal-join {


      background-image: url('../images/bg3.png');
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-size: 115%;
      padding: 2rem 0;
      .join-block {

        .block__title {

          margin-bottom: 5rem;
        }
        .col-md-6 {

          // padding-left: 0;
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(6) {
  
            padding-right: 0;
          }
  
        }
        .col-md-12 {

          // padding: 0;
        }
        input,
        textarea {
  
          border: solid 1px #9a9a9a;
          border-radius: 0;
          box-shadow: none;
          min-height: 45px;
        }
        textarea {

          height: 140px;
        }
        .form-actions {
          
          float: right;
          input {
  
            font-size: 12px;
            color: $white;
            padding: 18px 23px;
            border: solid 2px $brand-primary;
            text-transform: uppercase;
            letter-spacing: 2px;
            border-radius: 40px;
            font-weight: 600;
            display: inline-flex;
            margin-top: 2rem;
            transition: all 0.3s ease-in-out;
            &:hover {
        
              background-color: transparent; 
              color: $gray;
            }
          }
        }
      }
      .join-block-text {

        background-color: #ffffffdb;
        padding: 1rem 4rem;
        box-shadow: 4px 4px 11px #00000024;
        margin-bottom: 3rem;
        h3 {

          color: $brand-secondary;
          text-align: center;
          margin-bottom: 2rem;
          font-size: 3rem;
        }
      }
    }
  // ---------------------------------
  
  
  // sub service
  // ---------------------------------
    .sub-services {

      padding-top: 2rem;
      .block__title {

        font-weight: bold;
        font-size: 2rem;
        padding-bottom: 1rem;
        position: relative;
        display: inline-flex;
        &:after {

          content: '';
          width: 100%;
          height: 3px;
          background-color: $brand-primary;
          position: absolute;
          bottom: -1rem;
          left: 0;
        }
      }
      .block__content {

        border-top: solid 1px rgba(239, 7, 6, 0.36);
        padding-top: 2rem;
      }
      .view-content {

        .views-row {
    
          display: flex;
          border: 1px solid #e6e6e6;
          margin-bottom: 3rem;
          transition: all 0.3s ease-in-out;
          .right {

            position: relative; 
          }
          .left {

            padding: 1.5rem;
            .views-field-title {

              line-height: 24px;
              font-family: 'Montserrat';
              color: #23282d;
              font-size: 18px;
              font-weight: 600;
              a {

                color: #23282d;
              }
            }
            .views-field-body {

              margin-top: 1.5rem;  
              display: block;
            }
            .views-field-view-node {

              a {

                font-size: 15px;
                font-family: $font-family-secondary;
                line-height: 1.714em;
                display: flex;
                align-items: center;
                margin-left: -2rem;
                &:after {

                  content: "\f110";
                  font-family: icons;
                  font-size: 2rem;
                  position: relative;
                  top: 1px;
                  transition: all 0.15s ease 0s;
                  margin-left: 0.4rem;
                  opacity: 1;
                }
                &:before {

                  content: "\f110";
                  font-family: icons;
                  font-size: 2rem;
                  position: relative;
                  top: 1px;
                  transition: all 0.15s ease 0s;
                  margin-right: 0.4rem;
                  opacity: 0;
                }
                &:hover {

                  margin-left: 0.1rem;
                  color: $brand-primary;
                  &:after {

                    opacity: 0;
                  }
                  &:before {

                    opacity: 1;
                  }
                }
              }
            }
          }
          &:hover {

            box-shadow: 0 10px 28px rgba(0,0,0,.1), 0 8px 8px rgba(0,0,0,.07);
          }
        }
      }
    }
  // ---------------------------------



// -----------------------------------

