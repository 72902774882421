// Page Layout
// -----------------------------------------------------------------------------

// general inner page 
// ------------------------------------
  #breadcrumb {

    font-family: $font-family-secondary;
    display: flex;
    font-size: 11px;
    text-transform: uppercase;
    color: #888;
    line-height: 1.714em;
    font-weight: 400;
    margin-bottom: 30px;
    border-bottom: 1px solid #e5e8ec;
    padding: 1.4rem 0;
    .breadcrumb {

      padding: 0;
      margin-bottom: 0;
      background-color: transparent;
      border-radius: 0;
      a {

        color: #888;
        margin-left: 0.4rem;
      }
    }
  }
  .header-image {

    margin-top: -170px;
    background-color: #222;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    max-height: 400px;
    overflow: hidden;
    .page-header {
      
      position: absolute;
      z-index: 2;
      color: $white;
      border: none;
      h1 {

        font-size: 55px;
        line-height: 60px;
        letter-spacing: -1px;
        font-weight: 600;
        margin-bottom: 6rem;
        color: $brand-primary;
        text-shadow: 3px 3px black;
      }
    }
    img {

      opacity: 1;
    }
    &.top-space {

      margin-top: -85px !important;
    }
  }
  .pages-with-sidebar {

    display: flex;
    .page-content {

      padding-right: 3rem;
    }
    .sidebar {

      min-width: 250px;
      max-width: 250px;
    }
    &.sidebar-left {

      .page-content {

        padding-right: 0;
        padding-left: 3rem;
      }
    }
  }
// ------------------------------------

// about 
// ------------------------------------

  .page-node-34,
  .page-node-64 {

    .header-image {

      margin-top: -170px;
      background-color: #222;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      max-height: 400px;
      overflow: hidden;
      .page-header {
        
        position: absolute;
        z-index: 2;
        color: $white;
        border: none;
        h1 {

          font-size: 55px;
          line-height: 60px;
          letter-spacing: -1px;
          font-weight: 600;
          margin-bottom: 6rem;
        }
      }
      img {

        opacity: 1;
      }
    }
    .second-content {


      background: none;
      .region-second-content {
  
        display: flex;
        padding-bottom: 4rem;
      }
    }
    .separator {

      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $brand-primary;
      margin: 4rem 0;
      .left {

        padding: 0 6%;
        color: $white;
        line-height: 25px;
        font-family: $font-family-secondary;
        font-size: 16px;
        font-weight: 400;
        width: 50%;
        h1 {

          margin-bottom: 3rem;
        }
      }
      .righ {

        width: 50%;
        img {

          display: block;
          float: left;
          margin-right: 15px;
          width: 100%;
          height: auto;
        }
      }
    }
    .node-page {

      .field-name-body {

        padding: 3rem 0;
        .field-items {

          .field-item {

            display: flex;
            flex-wrap: wrap;
            .first-row {

              flex-basis: 50%;
              >div {
  
                padding-right:2rem;
                h3 {
  
                  font-weight: 600;
                  display: flex;
                  align-items: center;
                  &:before {
  
                    content: "\f114";
                    font-family: icons;
                    font-size: 4rem;
                    color: $brand-primary;
                    margin-right: 1rem;
                  }
                }
                &.right {
  
                  h3 {
  
                    &:before {
  
                      content: "\f123";
                    }
                  }
                }
              }
            }
            .second-row {

              flex-basis: 50%;
            }
            .bottom {

              width: 100%;
              margin-top: 2rem;
              h4 {

                font-weight: bold;
                font-family: $font-family-base;
                text-align: center;
                width: 100%;
                margin-bottom: 2rem;
              }
              .items {

                display: flex;
                justify-content: space-between;
                .item {

                 flex-basis: 50%; 
                 p {

                  &:before {

                    content: "\f12d";
                    font-family: icons;
                    color: $brand-primary;
                    margin-right: 1rem;
                  }
                 }
                }
              }
            }
          }
        }
      }
    }
  }
// ------------------------------------

// contacts
// ------------------------------------
  .page-node-35 ,
  .page-node-66 {

    .header-image {

      margin-top: -170px;
      background-color: #222;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      max-height: 400px;
      overflow: hidden;
      .page-header {
        
        position: absolute;
        z-index: 2;
        color: $white;
        border: none;
        h1 {

          font-size: 55px;
          line-height: 60px;
          letter-spacing: -1px;
          font-weight: 600;
          margin-bottom: 6rem;
        }
      }
      img {

        opacity: 1;
      }
    }


    #seventh_content {

      background-color: $white;
      display: flex;
      margin-bottom: 5rem;
      .region-seventh-content {

        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        background-color: #f0f6f6;
        .contact-details {
        
          // background-image: url('../images/pattern.jpg');  
          background-color: $brand-primary;
          color: $white;
          max-width: 35%;
          padding: 3rem 4rem;
          .block__title {
    
            font-size: 26px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: -.4px;
            margin-bottom: 3rem;
          }
          .block__content {
    
            .wrapper {
    
              >div {
    
                display: flex;
                align-items: flex-start;
                margin-bottom: 2.5rem;
                .icon {
    
                  margin-right: 1.5rem;
                  font-size: 3rem;
                }
                .text {
    
                  .title {
    
                    text-transform: uppercase;
                    letter-spacing: 3.3px;
                    font-weight: 600;
                    font-size: 14px;
                    margin-bottom: 1rem;
                  }
                }
              }
            }
          }
        }
        .block-webform {
  
          flex: 1;
          display: flex;
          padding: 0 4rem;
          .region-content {
  
            display: flex;
            align-items: center;
          }
        }
      }
    }
    #footer {

      margin-top: 0;
    }
  }
// ------------------------------------

// blog page
// ------------------------------------
  .page-articles {

    #content {

      .page-content {

        .region-content {
    
          .view-display-id-page {
    
            .view-content {
    
              .views-row {
    
                display: flex;
                border: 1px solid #e6e6e6;
                margin-bottom: 3rem;
                transition: all 0.3s ease-in-out;
                .left {
    
                  position: relative; 
                  .views-field-created {
    
                    background-color: $brand-secondary;
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: $white;
                    width: 75px;
                    height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;
                  }
                }
                .right {
    
                  padding: 2rem;
                  .views-field-title {
    
                    line-height: 24px;
                    font-family: 'Montserrat';
                    color: #23282d;
                    font-size: 18px;
                    font-weight: 600;
                    a {
    
                      color: #23282d;
                    }
                  }
                  .views-field-body {
    
                    margin-top: 1.5rem;  
                    display: block;
                  }
                  .views-field-view-node {
    
                    a {
    
                      font-size: 15px;
                      font-family: $font-family-secondary;
                      line-height: 1.714em;
                      display: flex;
                      align-items: center;
                      margin-left: -2rem;
                      &:after {
    
                        content: "\f110";
                        font-family: icons;
                        font-size: 2rem;
                        position: relative;
                        top: 1px;
                        transition: all 0.15s ease 0s;
                        margin-left: 0.4rem;
                        opacity: 1;
                      }
                      &:before {
    
                        content: "\f110";
                        font-family: icons;
                        font-size: 2rem;
                        position: relative;
                        top: 1px;
                        transition: all 0.15s ease 0s;
                        margin-right: 0.4rem;
                        opacity: 0;
                      }
                      &:hover {
    
                        margin-left: 0.1rem;
                        color: $brand-primary;
                        &:after {
    
                          opacity: 0;
                        }
                        &:before {
    
                          opacity: 1;
                        }
                      }
                    }
                  }
                }
                &:hover {
    
                  box-shadow: 0 10px 28px rgba(0,0,0,.1), 0 8px 8px rgba(0,0,0,.07);
                }
              }
            }
          }
        }
      }
    }
  }
// ------------------------------------

// blog nodes
// ------------------------------------
  .node-type-articles {

    #content {

      .page-content {

        .region-content {
    
          .col-sm-12  {

            .field-name-field-image {
              img {
                width: 100%;
                height: auto;
              }
            }
            .links {
              display: inline-block;
              color: $white;
              background-color: $brand-primary;
              padding: 0.97rem;
              font-size: 1.6rem;
            }
            .field-name-post-date {

              background-color: $brand-secondary;
              // position: absolute;
              right: 5rem;
              color: $white;
              width: 100px;
              height: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 700;
              float: right;
            }
            .field-name-title {

              h2 {

                margin-bottom: 40px;
                font-size: 36px;
                line-height: 42px;
                font-weight: 300;
                margin-top: 0;
              }
            }
            .field-name-body {

              margin-top: 2rem;
              .field-item {

                ul {

                  list-style: none;
                  padding-left: 1rem;
                  li {

                    margin-bottom: 1rem;
                    line-height: 1.9;
                    &:before {

                      content: "•";
                      font-size: 3rem;
                      position: relative;
                      color: $brand-primary;
                      line-height: 0;
                      top: 3px;
                      margin-right: 0.5rem;
                    }
                  }
                }
                h1,h2,h3,h4,h5,h6 {

                  font-size: 35px;
                  font-weight: 200;
                  line-height: 42px;
                  letter-spacing: -1.7px;
                  margin: 4rem 0;
                }
              }
            }
            .field-name-field-tags {

              margin-bottom: 2rem;
              border-top: 1px solid #e3e3e3;
              padding-top: 2em;

              .field-items {

                display: flex;
                flex-wrap: wrap;
                .field-item {
                  
                  margin: 0 0.5rem;
                  a {
  
                    font-size: 10px;
                    color: $gray;
                    padding: 10px 13px;
                    border: solid 1px $brand-primary;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    border-radius: 40px;
                    font-weight: 500;
                    display: inline-flex;
                    margin-top: 2rem;
                    &:hover {
  
                      background-color: $brand-primary; 
                      color: $white;
                    }
                  }
                }
              }
            }
            .social-share {

              display: flex;
              justify-content: flex-end;
              margin-top: 2rem;
              ul {

                list-style: none;
                display: flex;
                li {

                  a {

                    width: 25px;
                    height: 25px;
                    background-position-x: -50px;
                    background-size: cover;
                  }
                  &:nth-child(2) {

                    a {

                      background-position-x: -75px !important;
                    }
                  }
                  &:nth-child(3) {

                    a {

                      background-position-x: -125px !important;
                    }
                  }
                  &:nth-child(4) {

                    a {

                      background-position-x: -275px !important;
                    }
                  }
                }
              }
            }
            input,
            textarea {

              border: solid 1px #d8d8d8;
              border-radius: 0;
              box-shadow: none;
              min-height: 45px;
            }
            .comment-wrapper {

              margin-top: 2rem;
              padding-top: 2rem;
              border-top: 1px solid #e3e3e3;
              form {

                width: 70%;
              }
              .ckeditor_links {

                display: none;
              }
              .title {

                font-weight: 700;
                margin-bottom: 3rem;
              }
              .comment {

                padding: 20px;
                background: #f6f6f6;
                .media-heading {

                  a {

                    color: $text-color;
                    font-weight: 700;
                  }
                }
                .submitted {

                  display: none;
                }
              }
              .captcha {

                display: inline-block;
                margin-top: 1em;
              }
              .form-actions {

                display: inline-block;
                float: right;
              }
            }
            .filter-wrapper.form-wrapper{

              display: none !important;
            }
          }
        }
      }
    }
    &.logged-in {

      .comment .links {

        display: flex;
        a {

          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }

    .field-name-field-author {
      background-color: $brand-primary;
      padding: 1rem;
      color: $white;

      .field-name-field-image {

        background: white;
        border-radius: 50%;
        overflow: hidden;
      }

      p {
        color: $white !important; 
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
  
        h2 {
          margin-bottom: 0 !important;
        }
        @include mq-max(sm) {
          flex-direction: column;
          .col-sm-9 {
            margin: 2rem 0;
          }
        }
      }

    }

    .field-name-field-related-service {
      border: 1px solid #e6e6e6;
      transition: all 0.3s ease-in-out;
      margin-bottom: 2rem;

      .col-sm-12 {
        display: flex;
        align-items: center;

        .left {
          padding: 0 3rem;

          h2 {
            margin-bottom: 0 !important;
            font-size: 3rem !important;
          }
        }
        .field-name-node-link  {

          a {
            font-weight: bold;
            font-size: 1.6rem;
          }
        }

        @include mq-max(sm) {
          flex-direction: column;
          img { 
            width: 100%;
            height: auto;
          }
          .left {
            padding: 1rem 1.5rem;

          }
        }
      }

      &:hover {
        box-shadow: 0 10px 28px rgba(0,0,0,0.1), 0 8px 8px rgba(0,0,0,0.07);
      }
    }

    .field-name-field-next-up {
      margin: 3rem 0;
      text-align: center;

      a {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        width: 100%;
      }
    }
  }
// ------------------------------------

// taxonomy term
// ------------------------------------
  .page-taxonomy-term {

    #content {

      .page-content {

        .region-content {

          .node-teaser {
          
            .row {
      
              .col-sm-12  {
      
                display: flex;
                border: 1px solid #e6e6e6;
                margin-bottom: 3rem;
                transition: all 0.3s ease-in-out;
                padding: 0;
                .left {
      
                  position: relative; 
                  .field-name-post-date {
      
                    background-color: $brand-primary;
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: $white;
                    width: 75px;
                    height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;
                  }
                }
                .right {
      
                  padding: 2rem;
                  .field-name-title {
      
                    line-height: 24px;
                    font-family: 'Montserrat';
                    color: #23282d;
                    font-size: 18px;
                    font-weight: 600;
                    a {
      
                      color: #23282d;
                    }
                  }
                  .field-name-body {
      
                    margin-top: 1.5rem;  
                    display: block;
                  }
                  .field-name-node-link {
      
                    a {
      
                      font-size: 15px;
                      font-family: $font-family-secondary;
                      line-height: 1.714em;
                      display: flex;
                      align-items: center;
                      margin-left: -2rem;
                      &:after {
      
                        content: "\f110";
                        font-family: icons;
                        font-size: 2rem;
                        position: relative;
                        top: 1px;
                        transition: all 0.15s ease 0s;
                        margin-left: 0.4rem;
                        opacity: 1;
                      }
                      &:before {
      
                        content: "\f110";
                        font-family: icons;
                        font-size: 2rem;
                        position: relative;
                        top: 1px;
                        transition: all 0.15s ease 0s;
                        margin-right: 0.4rem;
                        opacity: 0;
                      }
                      &:hover {
      
                        margin-left: 0.1rem;
                        color: $brand-primary;
                        &:after {
      
                          opacity: 0;
                        }
                        &:before {
      
                          opacity: 1;
                        }
                      }
                    }
                  }
                }
                &:hover {
      
                  box-shadow: 0 10px 28px rgba(0,0,0,.1), 0 8px 8px rgba(0,0,0,.07);
                }
              }
            }
          }
        }
      }
    }
  }
// ------------------------------------

// services categories terms
// ------------------------------------
  .page-taxonomy-term-1,
  .page-taxonomy-term-2,
  .page-taxonomy-term-3,
  .page-taxonomy-term-28,
  .page-taxonomy-term-29,
  .page-taxonomy-term-30 {

    #main-wrapper {

      #content {

        .page-content {

          .page-header {

            border: none;
            font-size: 2rem;  
            padding: 0 1rem;
            margin-bottom: 3rem;
          }
          
          .region-content {

            display: flex;
            flex-wrap: wrap;
            .term-listing-heading {

              width: 100%;
              .vocabulary-services-category {

                padding: 0 1rem;
                margin: 2rem 0;
                .taxonomy-term-description {

                  p {

                    line-height: 29px;
                    font-family: $font-family-secondary;
                    color: #222;
                    font-size: 16px;
                    font-weight: 400;
                  }
                }
              }
            }
            .view-display-id-page {

              .view-content {

                display: flex;
                flex-wrap: wrap;
                .views-row  {
    
                  flex-basis: 30%;
                  width: 30%;
                  margin: 0 1rem;
                  .row {
    
                    margin: 0;
                    .col-sm-12  {
      
                      display: flex;
                      flex-direction: column;
                      min-height: 470px;
                      .field-name-field-image {
    
                        position: relative;
                        img {
    
                          width: 100%;
                          height: auto;
                        }
                        &:after {
    
                          content: "";
                          width: 70px;
                          height: 6px;
                          background-color: $brand-primary;
                          position: absolute;
                          left: 2rem;
                          bottom: -6px;
                        }
                      }
                      .field-name-title {
    
                        margin-top: 2rem;
                        padding: 0 2rem;
                        h2 {
    
                          font-size: 2rem;
                          font-weight: 700;
                          a {
    
                            color: #222;
                          }
                        }
                      }
                      .field-name-body {
    
                        padding: 0 2rem;
                        p {
    
                          line-height: 25px;
                          font-family: $font-family-secondary;
                          color: #222;
                          font-size: 14px;
                          font-weight: 400;
                        }
                      }
                      .field-name-node-link {
    
                        padding: 0 2rem;
                        text-align: right;
                        font-weight: bold;
                        font-size: 1rem;
                        margin-top: auto;
                        margin-bottom: 2rem;
                        a {
    
                          color: $black;
                          text-transform: uppercase;
                        }
                      }
                      &:hover {
    
                        .field-name-title {
    
                          h2 {
    
                            a {
    
                              color: $brand-primary;
                            }
                          }
                        }
                        .field-name-node-link {
    
                          a {
    
                            color: $brand-primary;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

// ------------------------------------

// services node
// ------------------------------------
  .node-type-services,
  .node-type-solution {

    #main-wrapper {

      header .top-header {

        background-color: $brand-primary;
      }
      .page-header {
  
        position: relative;
        margin: 0;
        padding: 0;
        img {
  
          width: 100%;
          height: auto;
        }
        .text {
  
          position: absolute;
          bottom: 0;
          z-index: 9;
          color: $white;
          padding-left: 3rem;
          padding-bottom: 3rem;
          p {
  
            color: $white;
          }
        }
        &:after {
  
          content: '';
          width: 100%;
          height: 100%;
          background: -webkit-linear-gradient(left, rgba(242,0,0,1) 0%,rgba(2, 16, 79, 0.4) 100%); 
          position: absolute;
          z-index: 1;
          top: 0;
        }
      }
      .region-content {
  
        .row {
  
          .col-sm-12 {
  
            .field-name-title {
  
              margin: 2rem 0;
            }
            .field-name-field-image {
  
              margin-bottom: 3rem;
              width: 100%;
              height: auto;
            }
            .field-name-body {
  
              // margin-top: 2rem;
              margin-bottom: 2rem;
              .field-item {
  
                ul {
  
                  list-style: none;
                  padding-left: 1rem;
                  li {
  
                    margin-bottom: 1rem;
                    line-height: 1.9;
                    &:before {
  
                      content: "•";
                      font-size: 3rem;
                      position: relative;
                      color: $brand-primary;
                      line-height: 0;
                      top: 3px;
                      margin-right: 0.5rem;
                    }
                  }
                }
                h1,h2,h3,h4,h5,h6 {
  
                  font-size: 35px;
                  font-weight: 200;
                  line-height: 42px;
                  letter-spacing: -1.7px;
                  margin-top: 0;
                }
                a {
  
                  text-transform: uppercase;
                  font-family: "Montserrat",sans-serif;
                  font-weight: 500;
                  text-decoration: underline;
                  font-size: 1.5rem;
                  &:hover {
  
                    color: $brand-secondary;
                  }
                }
              }
            }
            .inner-wrap {
  
              display: flex;
              justify-content: space-around;
  
            }
            .inner-services {
  
              display: flex;
              flex-direction: column;
              min-height: 470px;
              flex-basis: 40%;
              border: 1px solid #e6e6e6;
              .field-name-field-image {
  
                position: relative;
                img {
  
                  width: 100%;
                  height: auto;
                }
                &:after {
  
                  content: "";
                  width: 70px;
                  height: 6px;
                  background-color: $brand-primary;
                  position: absolute;
                  left: 2rem;
                  bottom: -6px;
                }
              }
              .field-name-title {
  
                margin: 0;
                padding: 0 2rem;
                h2 {
  
                  font-size: 2rem;
                  font-weight: 700;
                  a {
  
                    color: #222;
                  }
                }
              }
              .field-name-body {
  
                padding: 0 2rem;
                p {
  
                  line-height: 25px;
                  font-family: $font-family-secondary;
                  color: #222;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
              .field-name-node-link {
  
                padding: 0 2rem;
                text-align: right;
                font-weight: bold;
                font-size: 1rem;
                margin-top: auto;
                margin-bottom: 2rem;
                a {
  
                  color: $black;
                  text-transform: uppercase;
                }
              }
              &:hover {
  
                .field-name-title {
  
                  h2 {
  
                    a {
  
                      color: $brand-primary;
                    }
                  }
                }
                .field-name-node-link {
  
                  a {
  
                    color: $brand-primary;
                  }
                }
              }
            }
          }
        }
      }
      &.top-space {
        
        margin-top: 85px;
      }
    }
    
  }
// ------------------------------------
  
// Testimonials
// ------------------------------------

  .page-testimonials {

    #main-wrapper {

      .page-content {

        .view-testimonials {

          .views-row {

            margin-bottom: 2rem;
            padding: 2rem;
            box-shadow: 12px 12px 17px rgba(0,0,0,0.08);
            border: solid 1px rgba(170,170,170,0.21);
            .views-field-body {
  
              p {
  
                margin-bottom: 40px;
                line-height: 30px;
                font-weight: 300;
                font-size: 18px;
              }
              &:before {
  
                content: "\f113";
                font-family: icons;
                font-size: 2rem;
                color: $brand-primary;
              }
            }
            .bottom {
  
              display: flex;
              flex-direction: column;
              padding-left: 10rem;
              position: relative;
              .views-field-title {
  
                letter-spacing: 3px;
                margin-bottom: 5px;
                line-height: 16px;
                font-weight: 600;
                font-size: 11px;
                text-transform: uppercase;
              }
              .views-field-field-position {
  
                font-size: 14px;
                color: $text-color;
              }
              &:before {
  
                content: '';
                width: 9rem;
                height: 2px;
                position: absolute;
                left: 0rem;
                margin-top: 0.5rem;
                background-color: $text-color;
              }
            }
          }
        }
      }
      .pager--infinite-scroll {

        a {

          display: inline-block;
          padding: 11px 25px;
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 30px;
          margin-top: 2rem;
          margin-bottom: 1rem;
          font-weight: bold;
        }
      }
    }
  }
// ------------------------------------

// under construction
// ------------------------------------

  .page-node-1 {

    @import url('https://fonts.googleapis.com/css?family=Black+Han+Sans');
    header .top-header {

      display: none;
    }
    header .bot-header {

      background: transparent;
    }
    .header .bot-header .container .region-main-menu {

      display: none;
    }
    .content {

      margin-top: -83px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      p {

        position: absolute;
        color: $brand-secondary;
        text-transform: uppercase;
        font-family: 'Black Han Sans', sans-serif;
        font-size:4rem;
      } 
      a {

        position: absolute;
        font-size: 18px;
        color: $brand-primary;
        padding: 18px 23px;
        border: solid 2px $brand-primary;
        text-transform: uppercase;
        letter-spacing: 2px;
        border-radius: 40px;
        font-weight: 600;
        display: inline-flex;
        margin-top: 8rem;
        &:hover {
    
          background-color: $brand-primary; 
          color: $white;
        }
      }
    }
  }
// ------------------------------------

// solutions page
// ------------------------------------

  .page-node-120,
  .page-node-140 {

    
    .second-content {
      
      background-color: transparent;
    }
    .separator {

      background-color: $brand-primary;
      .solutions-page-separator {

        .block__content {

          margin-bottom: 4rem;
          .view-header {

            color: $white;
            text-align: center;
            margin: 4rem 0;
          }
          .view-content {

            display: flex;
            justify-content: space-between;
            background-color: $white;
            box-shadow: 15px 14px 20px rgba(0, 0, 0, 0.102);
            .views-row {

              padding: 2rem;
              width: 32%;
              .views-field-title {

                .field-content {

                  font-family: $font-family-base;
                  font-weight: bold;
                  font-size: 2rem;
                }
              }
              .views-field-body {

                margin-top: 3rem;
                .field-content {

                  p {

                    font-weight: bold;
                    margin-bottom: 1rem;
                    strong {

                      display: flex;
                      margin-left: 1rem;
                    }
                    &:before {

                      content: "\f12d";
                      font-family: icons;
                      color: $brand-primary;
                      margin-right: 0.4rem;
                    }
                  }
                  h2 {

                    color: $brand-primary;
                    margin-top: 4rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    #content {

      padding: 3rem 0;
    }
  }
// ------------------------------------

// our services
// ------------------------------------
  .page-our-services {

    .view-services.view-display-id-page {

      .services-catagories {
        display: flex;
        flex-wrap: wrap;

        h3 {
          width: 100%;
          margin-top: 0;
          margin-bottom: 2.4rem;
          padding-left: 1rem;
          font-weight: bold;
        }

        .views-row  {
  
          flex-basis: 30%;
          width: 30%;
          margin: 0 1rem;
          display: flex;
          flex-direction: column;
          min-height: 470px; 
          border: 1px solid #e6e6e6;
          margin-bottom: 3rem;
          transition: all 0.3s ease-in-out;
          padding: 0;  
        
          &:hover {

            box-shadow: 0 10px 28px rgba(0,0,0,0.1), 0 8px 8px rgba(0,0,0,0.07);
            .views-field-title {

              .field-content {

                a {

                  color: $brand-primary;
                }
              }
            }
            .views-field-view-node {

              a {

                color: $brand-primary;
              }
            }
          }
        }
      }

      .views-field-field-image {

        position: relative;
        img {

          width: 100%;
          height: auto;
        }
        &:after {

          content: "";
          width: 70px;
          height: 6px;
          background-color: $brand-primary;
          position: absolute;
          left: 2rem;
          bottom: -6px;
        }
      }

      .views-field-title {

        margin-top: 2rem;
        padding: 0 2rem;
        .field-content {

          font-size: 2rem;
          font-weight: 700;
          a {

            color: #222;
          }
        }
      }

      .views-field-body {

        padding: 0 2rem;
        p {

          line-height: 25px;
          font-family: $font-family-secondary;
          color: #222;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .views-field-view-node {


        padding: 0 2rem;
        text-align: right;
        font-weight: bold;
        font-size: 1rem;
        margin-top: auto;
        margin-bottom: 2rem;
        a {

          color: $black;
          text-transform: uppercase;
        }
      }
    }
  }
// ------------------------------------
