@media (max-width:769px) {

  // general 
  body #mm-0 {

    width: 100vw;
    margin-bottom: 4rem;
  }
  .mm-panels {

    margin-top: 10rem;
    .mm-listitem>a, .mm-listitem>span {

      font-size: 2rem;
      font-weight: bold;
      padding: 2rem 1rem;
    }
  }
  // header 
  header .bot-header .container {

    padding: 1rem ;
    .region-main-menu {
  
      .block-ultimenu {
  
        display: none;
      }
      .block-responsive-menu {
  
        display: block;
        .responsive-menu-toggle-icon {
  
          .label {
    
            display: none;
          }
          .icon {
    
            background: $text-color;
          }
          &:after,
          &:before {
    
            background: $text-color !important;
          }
        }
      }
    }
  }

  header .top-header .container .topheader-contacts {

    padding-right: 0rem;
  }
  // front page
  // -------------------------------------
    // slideshow

    .slideshow .region-slide-show .front-page-slideshow .view-content .views-slideshow-cycle-processed {

      .views_slideshow_main .views-row {
        
        justify-content: center;
        .content {
          
          left: unset;
          
        }
      }
      .views-slideshow-controls-bottom .views-slideshow-pager-fields .views-slideshow-pager-field-item {

        flex-direction: column;
        align-self: center;
        justify-content: center;
        position: relative;
        a {

          padding: 0 1rem;
          flex-direction: column;
          align-self: center;
          justify-content: center;
          text-align: center;
          div.subtitle {
            
            font-size: 13px;
          }
        }
        &:before {
    
          font-size: 3rem;
          position: absolute;
          top: 1rem;
          margin: 0;
        }
      }
    }
    // about section
    .front .region-content {

      flex-direction: column;
      .about-images-parallax {

        padding-top: 2rem;
        .block__content {
  
  
          .parallax-image-1 {
    
            display: none;
          }
          .parallax-image-2 {
    
            position: relative;
            top: unset;
            left: unset;
            width: 100%;
          }
        }
      }
    }
    // solutions section 
    .front-page-solution .block__content .view-content .views-slideshow-cycle-processed {
      flex-direction: column;
      .views_slideshow_cycle_main {
        
        width: 100%;
      }
      .views-slideshow-controls-top .views-slideshow-pager-fields {
        
        flex-direction: row;
        .views-slideshow-pager-field-item .views-field-title .views-content-title a {

          &:before {

            content: '';
            width: 100%;
            height: 3px;
            position: absolute;
            left: 0px;
            top: unset;
            bottom: 0;
            background-color: transparent;
            z-index: 99;
          }
          &:after {

            content: '';
            width: 100%;
            height: 3px;
            position: absolute;
            left: 0px;
            top: unset;
            bottom: 0;
            background-color: rgba(220, 223, 229, 0.48);
          }
        }
      }
    }
    // services section
    .front-page-services .block__content {

      .quicktabs-tabpage .view .view-header {

        margin-bottom: 10rem;
      }
      #quicktabs-services {

        position: relative;
        .item-list {


          position: absolute;
          bottom: unset;
          top: 13rem;
          width: 100%;
          display: flex;
          justify-content: center;
          .quicktabs-tabs {
      
            flex-direction: row; 
            li {

              a {

                border-left: unset;
                border-bottom: solid 3px rgba(220, 223, 229, 0.48);
              }
            }
          }
        }
      }
    }
    // video section
    .front .fourth-content  {

      height: 400px;
      video {
  
        top: 0;
      }
    }
    // contact block
    .seventh-content {

      padding: 0;
      .region-seventh-content {

        flex-direction: column;
      }
      .request-quote-webforme {

        width: 100%;
        .col-md-12,
        .col-md-6,
        .webform-component--select-an-area-of-interest {

          padding: 0 15px !important;
        }
      }
    }
    // join
    .join .container p {

      width: 60%;
    }

  // -------------------------------------

  // about page
    .page-node-34 .second-content .region-second-content, 
    .page-node-64 .second-content .region-second-content {

      flex-direction: column;
    }
    .about-page-image-block-top .block__content {


      width: unset;
      height: unset; 
      text-align: center;
      margin-bottom: 2rem;
      &:before {

        content: unset;
      }
    }
    .block--about-farah-jahed {

      padding: unset;
    }
    .page-node-34,
    .page-node-64 {
      
      .separator {
        
        flex-direction: column;
        .left {
          
          
          width: 100%;
          padding: 3rem 2%;
        }
        .righ {
          
          width: 100%;
          margin-top: 2rem;
        }
      }
      .node-page .field-name-body {

        padding-top: 0;
        .field-items .field-item {
  
          flex-direction: column;
        }
      }
    }

    // inner page 

    .pages-with-sidebar {

      flex-direction: column;
      .page-content {

        padding-right: 0;
      }
      .sidebar {

         min-width: unset; 
         max-width: unset; 
      }
    }
    .page-taxonomy-term-1,
    .page-taxonomy-term-2,
    .page-taxonomy-term-3,
    .page-taxonomy-term-30 {

      #main-wrapper #content .page-content .region-content .view-display-id-page .view-content .views-row {
  
        
        flex-basis: 46%;
        width: 46%;
      }
    }
    .page-node-120 {

      .pages-with-sidebar {

        flex-direction: column-reverse;
      }
    }
    
    .page-node-35 #seventh_content .region-seventh-content, 
    .page-node-66 #seventh_content .region-seventh-content {

      flex-direction: column-reverse;
      .contact-details {

        max-width: unset;
      }
    }
  #footer {

    position: static;
    .top-footer .container .region-footer {

      flex-wrap: wrap;
    }
    .footer-blocks {
      
      flex-basis: 50%;
      &.footer-social-media {

        top: 90%;
        width: 38%;
      }
    }
  }



  .slideshow {
    
    .region-slide-show {

      .front-page-slideshow {

        .view-content {

          .views-slideshow-cycle-processed {

            .views_slideshow_main {

              .views-row {

                .content {
                  top: 5%;

                  .views-field-title {
                    margin-bottom: 1rem;

                    .field-content {
                      font-size: 2.5em;
                    }
                  }
                  .views-field-field-description {

                    .field-content {
                      font-size: 1.3em;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  
  .front {

    .slideshow {

      .region-slide-show {

        .front-page-slideshow {

          .view-content {

            .views-slideshow-cycle-processed {

              .views-slideshow-controls-bottom {

                .views-slideshow-pager-fields {

                  .views-slideshow-pager-field-item {

                    a {
                      padding: 7rem 1rem 1rem 1rem;

                      div.title {
                        font-size: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .front-page-solution {

      .block__content {

        .view-content {

          .views-slideshow-cycle-processed {

            .views-slideshow-controls-top {

              .views-slideshow-pager-fields {

                .views-slideshow-pager-field-item{

                  .views-field-title {

                    .views-content-title {

                      a {
                        font-size: 1.5rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .front-page-solution .block__content .view-content .views-slideshow-cycle-processed .views-slideshow-controls-top {

      height: 60px;
      width: 100%;
    }
    .views-slideshow-pager-fields > div {

      padding: 0;
    }
    .front-page-solution .block__content .view-content .views-slideshow-cycle-processed .views-slideshow-controls-top .views-slideshow-pager-fields .views-slideshow-pager-field-item .views-field-title .views-content-title:after {

      font-size: 4rem;
    }
    .front-page-solution .block__content .view-content .views-slideshow-cycle-processed .views_slideshow_cycle_main .views-slideshow-cycle-main-frame .views-slideshow-cycle-main-frame-row .views-row .right .views-field-body .field-content p {

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &:after {
        top: 14px;
      }
      &:before {
        top: 14px;
      }
    }
    .front-page-services .block__content #quicktabs-services .item-list .quicktabs-tabs {

      height: 60px;
      width: 100%;
    }
    .front-page-services .block__content #quicktabs-services .item-list .quicktabs-tabs li a:after {

      font-size: 5rem;
    }
    .front-page-services .block__content #quicktabs-services .item-list {

      top: 17rem;
    }
    .front-page-blog {

      .block__content {

        .view-content {

          .views-row {
            margin: auto;

            .image-title {

              .views-field-field-image {

                .field-content {

                  img {
                    width: 270px;
                    height: 270px;
                  }
                }
              }
            }
            .body-link {
              height: 270px;

              .views-field-body {

                p {
                  font-size: 1.6rem;
                }
              }
            }
          }
          .views-row-3 {
            display: none;
          }
        }
      }
    }

    #fourth_content {
      height: 330px;
  
      .front-page-separator {
  
        .block__title {
          font-size: 3.2em;
        }
      }
    }
  }

  .front-page-blocks.title-lines {
    
    .block__title {
      letter-spacing: 1px;
      font-size: 13px;
    }
  }

  .footer-blocks.footer-news-letter {

    .webform-client-form>div {

      .form-actions {
        right: 25px;
      }
    }
  }

  .modal-join {

    .container {
      height: unset;

      .webform-component {
        padding: 0;
      }
    }
    .join-block {

      .form-actions {
        text-align: center;
        float: unset;
      }
    }
  }

  .about-page-image-block-top {

    .block__content {

      img {
        width: 100%;
      }
    }
  }

  .page-articles {

    #content {

      .page-content {

        .region-content {

          .view-display-id-page {

            .view-content {

              .views-row {
                flex-direction: column;

                .left {

                  .field-content {

                    img {
                      width: 100%;
                      height: auto;
                    }
                  }
                }
                .right {

                  .views-field-view-node {
                    float: right;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .pages-with-sidebar.sidebar-left {

    .page-content {
      padding-left: 0;
    }
  }

  .sidebar-subscribe {

    .block__content {

      .form-actions {

        input {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .request-quote-webforme {

    .form-actions {

      input {
        display: flex;
        justify-content: center;
      }
    }
  }

  .sub-services {

    .view-content {

      .views-row {
        flex-direction: column;

        .right {

          .field-content {

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}


@media (max-width:600px) {
  
  .front {

    .slideshow {

      .region-slide-show {
        
        .front-page-slideshow {
  
          .view-content {
  
            .views-slideshow-cycle-processed {

              .views_slideshow_main {

                .views-row {

                  .content {
                    top: 20%;
                  }
                }
              }
              .views-slideshow-controls-bottom {
  
                .views-slideshow-pager-fields {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

   .region-content {
     margin-top: 0;
   }
  }
  .page-our-services {
    .pages-with-sidebar {

      flex-direction: column-reverse;
    }
  }
  .page-our-services .view-services.view-display-id-page .view-content .services-catagories .views-row {

    flex-basis: 43%;
    width: 100%;
  }

  .node-type-services {

    #main-wrapper {

      .page-header {

        .text {

          p {
            display: none;
          }
        }
      }
    }
  }

  .mm-page__blocker.mm-slideout {

    position: absolute;
    z-index: 999999;
  }

  #footer {

    .bottom-footer {

      p {
        font-size: 12px;
      }
    }
  }
}
