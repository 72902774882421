// @file
// SASS partials import
// -----------------------------------------------------------------------------

// Bootstrap global partials.
// -----------------------------------------------------------------------------
@import "base/variables";
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Bootswatch partial
// -----------------------------------------------------------------------------
@import "bootswatch/variables";

// farah global partials.
// -----------------------------------------------------------------------------
@import "radix/mixins";
@import "base/mixins";
@import "base/helpers";

// Bootstrap reset and dependencies
// -----------------------------------------------------------------------------
@import "bootstrap/normalize";
@import "bootstrap/print";
@import "bootstrap/glyphicons";

// Bootstrap core
// -----------------------------------------------------------------------------
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// Bootstrap components
// -----------------------------------------------------------------------------
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
@import "bootstrap/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/progress-bars";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/panels";
@import "bootstrap/responsive-embed";
@import "bootstrap/wells";
@import "bootstrap/close";

// Bootstrap components w/ JavaScript
// -----------------------------------------------------------------------------
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Bootstrap utility classes
// -----------------------------------------------------------------------------
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";

// FontAwesome
// -----------------------------------------------------------------------------
@import "font-awesome";

// Bootswatch
// -----------------------------------------------------------------------------
@import "bootswatch/bootswatch";

// Radix styles overrides and fixes.
// -----------------------------------------------------------------------------
// Radix styles overrides and fixes.
// -----------------------------------------------------------------------------
@import "radix/admin";
@import "radix/comment";
@import "radix/form";
@import "radix/layout";
@import "radix/maintenance";
@import "radix/modal";
@import "radix/nav";
@import "radix/node";
@import "radix/panel";
@import "radix/structure";
@import "radix/view";

// farah partials
// --------------------------------------------------
@import "base/typography";
@import "base/icons";
@import "layout/_page.scss";
@import "components/_block.scss";
@import "components/_comment.scss";
@import "components/_field.scss";
@import "components/_form.scss";
@import "components/_menu.scss";
@import "components/_node.scss";
@import "components/_panel.scss";
@import "components/_structure.scss";
@import "components/_term.scss";
@import "components/_user.scss";
@import "components/_view.scss";

// general styles
// --------------------------------------------------

  a {
    transition: all 0.3s ease-in-out;

    &:hover {
      text-decoration: none;
    }
  }

  #edit-preview {
    display: none;
  }

  .owl-carousel .owl-nav button.owl-next, 
  .owl-carousel .owl-nav button.owl-prev, 
  .owl-carousel button.owl-dot {
    outline: none !important;
  }

  .translation_fa,
  .translation_en {
    display: none;
  }


  @keyframes rotate-center {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes border-fill {
    0% {
      
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes visible {
    0% {
      
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }

  body {

    display: flex;
    flex-direction: column;
    position: relative;    
    header {

      order: 1;
      z-index: 99;
    }
    #mm-0 {

      order: 2;
      position: static;
      z-index: 3;
      margin-bottom: 42rem;
      width: 100vw;
      overflow: hidden;
    }
    #footer {

      order: 3;
      z-index: 2;
    }
  }

  p {
    line-height: 25px;
    margin: 0 0 15px;
    font-family: 'Open Sans';
    color: #222;
    font-size: 15px;
    font-weight: 400;
  }

  .round-button {

    font-size: 12px;
    color: $gray;
    padding: 18px 23px;
    border: solid 2px $brand-primary;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 40px;
    font-weight: 600;
    display: inline-flex;
    margin-top: 2rem;
    &:hover {

      background-color: $brand-primary; 
      color: $white;
    }
    &.red {

      background-color: $brand-primary;
      color: $white;
      &:hover {

        background-color: transparent;
      }
    }
    &.blue {

      background-color: $brand-secondary;
      border-color: $brand-secondary;
      color: $white;
      &:hover {

        background-color: transparent;
      }
    }
    &.white-text {

      color: $white;
      &.blue-border {
  
        color: $white;
        border-color: $brand-secondary;
        &:hover {

          background-color: $brand-secondary;
          color: $white;
        }
      }
    }

  }

  blockquote {

    background-color: #eeeff2;
    font-size: 18px;
    line-height: 28px;
    font-style: normal;
    padding: 26px 30px;
    border-color: $brand-primary; 
    p {

      font-size: 18px;
      line-height: 32px;
      font-weight: 300;
    }
  }

  #main-wrapper {

    background-color: $white;
  }

// --------------------------------------------------

// front page sections
// --------------------------------------------------
  
  .front {

    .region-content {
  
      margin-top: 7rem;
      margin-bottom: 3rem;
      display: flex;
      .block {
        flex: 1;
      }
    }
    .second-content {

      padding: 2rem 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left bottom;
      background-color: #f3f3f5;
      background-color: #f3fbfc;
    }

    .third-content,
    .fifth-content {
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-size: 115%;
      padding: 2rem 0;
    }
    .third-content {

      min-height: 730px;
      position: relative;
    }

    .fourth-content {

      background-color: $black;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      padding: 5rem 0;
      height: 500px;
      video {
  
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        top: -200px;
        z-index: 1;
        opacity: 0.6;
      }
    }

    .sixth-content {

      background: $brand-secondary url('../images/bg3.png');
      background-position: center ;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
      background-size: 115%;
      transform: translate3d(0,0,0);
      -webkit-transform: translate3d(0,0,0);
      padding: 2rem 0;
      position: relative; 
      .container {

        position: relative;
        z-index: 10;
        a {

          position: absolute;
          right: 4rem;
          bottom: 7rem;
          z-index: 9;
        }
      }
      &:after {

        content: "";
        width: 100%;
        height: 100%;
        background-color: #12172d;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;
        opacity: 0.8;
      }
    }

    .seventh-content {

      background-color: #f0f6f6;
      padding: 5rem 0;
      .region-seventh-content {

        display: flex;
        align-items: flex-start;
      }
    }
  }
  .seventh-content {

    background-color: #f0f6f6;
    padding: 5rem 0;
    .region-seventh-content {

      display: flex;
      align-items: flex-start;
    }
  }
  .second-content {

    padding: 2rem 0;
    // background-image: url('../images/bg1.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-color: #f3f3f5;
  }
// --------------------------------------------------

// join us section
// --------------------------------------------------
  .join {

    background-color: $brand-primary;
    padding: 1rem 0;
    .container {

      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
    
        font-size: 18px;
        font-weight: 100;
        line-height: 30px;
        font-family: 'Open Sans';
        color: $white;
        margin: 0;
      }
      a {

        margin: 0;
        border-color: $white;
        &:hover {

          background-color: $white;
          color: $brand-primary;
        }
      }
    }
  }
// --------------------------------------------------


// footer general
// --------------------------------------------------
  #footer {

    margin-bottom: 0;
    margin: 0;
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: white;
    .top-footer {
      
      padding-bottom: 5rem;
      .container {

        position: relative;
        .region-footer {
      
          display: flex;
        }
      }
    }
    .bottom-footer {
  
      background-color: #222;
      padding: 3rem 0;
      text-align: center;
      p {
        
        margin: 0;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
  
          max-width: 25px;
          margin: 0 0.4rem;
        }
        a {

          color: $white;
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }
  }

// --------------------------------------------------

// video modal
// --------------------------------------------------

  .modal-wrapper {

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    background-color: $white;
    display: none;
    overflow: scroll;
    transition: opacity .6s cubic-bezier(.645,.045,.355,1);
    .container {

      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex-direction: column;
    }

    .modal-logo {

      width: 170px;
    }

    .close-search {

      cursor: pointer;
      position: absolute;
      right: 1%;
      top: 2%;
      .icon {

        width: 64px;
        height: 64px;
        transition: all .3s;
        fill: $brand-primary;
        &:hover {
          fill: $brand-primary;
        }
      }

    }
    &.show {

      display: block;
    }
  }

  // Modal close button
  .js_modalCloseBtn {

    width: 44px;
    height: 44px;
    box-sizing: border-box;
    opacity: 0;
    position: absolute;
    top: 100%;
    margin-top: 20px;
    margin-right: 20px;
    border: 2px solid #d4d4d4;
    border-radius: 50%;
    transition: opacity .6s cubic-bezier(.645,.045,.355,1);
    background-color: transparent;
    outline: 0;
    z-index: 20;
    transition: opacity .4s cubic-bezier(.645,.045,.355,1),-webkit-transform .6s cubic-bezier(.215,.61,.355,1);
    transition: opacity .4s cubic-bezier(.645,.045,.355,1),transform .6s cubic-bezier(.215,.61,.355,1);
    transition: opacity .4s cubic-bezier(.645,.045,.355,1),transform .6s cubic-bezier(.215,.61,.355,1),-webkit-transform .6s cubic-bezier(.215,.61,.355,1);
    pointer-events: auto;
    cursor: pointer;
    transform: scale(1);
    opacity: 1;
    background-color: $white;
    &:before {
      
      width: 44px;
      height: 44px;
      box-sizing: border-box;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      content: "";
      display: block;
      left: -2px;
      top: -2px;
      border: 3px solid $brand-primary;
      border-radius: 50%;
      color: $brand-primary;
      -webkit-transform: scale(1.4);
      transform: scale(1.4);
      transition: opacity .6s cubic-bezier(.645,.045,.355,1),border .6s cubic-bezier(.645,.045,.355,1),-webkit-transform .6s cubic-bezier(.645,.045,.355,1);
      transition: transform .6s cubic-bezier(.645,.045,.355,1),opacity .6s cubic-bezier(.645,.045,.355,1),border .6s cubic-bezier(.645,.045,.355,1);
      transition: transform .6s cubic-bezier(.645,.045,.355,1),opacity .6s cubic-bezier(.645,.045,.355,1),border .6s cubic-bezier(.645,.045,.355,1),-webkit-transform .6s cubic-bezier(.645,.045,.355,1);
    }

    .icon-modalClose {

      display: block;
      position: absolute;
      width: 14px;
      height: 14px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate3d(-50%,-50%,0);
      transform: translate3d(-50%,-50%,0);
      svg.icon {

        display: block;
        width: 100%;
        height: 100%;
      }
    }
    &:hover {

      &:before {

        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        transition: opacity .6s cubic-bezier(.215,.61,.355,1),border .6s cubic-bezier(.645,.045,.355,1),-webkit-transform .6s cubic-bezier(.215,.61,.355,1);
        transition: transform .6s cubic-bezier(.215,.61,.355,1),opacity .6s cubic-bezier(.215,.61,.355,1),border .6s cubic-bezier(.645,.045,.355,1);
        transition: transform .6s cubic-bezier(.215,.61,.355,1),opacity .6s cubic-bezier(.215,.61,.355,1),border .6s cubic-bezier(.645,.045,.355,1),-webkit-transform .6s cubic-bezier(.215,.61,.355,1);
      }
    }
  }
// --------------------------------------------------

// loader 
// -----------------------
.loader {

  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preloader {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.4s;
  -moz-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.4s;
  -ms-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.4s;
  -o-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.4s;
  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.4s
}

.preloader::before,
.preloader::after {
  content: '';
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute
}

.preloader::before {
  z-index: 2;
  background: #f5f5f5;
  -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.1s;
  -moz-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.1s;
  -ms-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.1s;
  -o-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.1s;
  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.1s
}

.preloader::after {
  z-index: 1;
  background: #f2f2f2;
  -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.25s;
  -moz-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.25s;
  -ms-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.25s;
  -o-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.25s;
  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1) 0.25s
}

.preloader.end {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%)
}

.preloader.end::before {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%)
}

.preloader.end::after {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%)
}

.preloader .loader {
  width: 200px;
  height: 200px;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  z-index: 3
}

.preloader .loader .triangle {
  stroke-dasharray: 17;
  -webkit-animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
  -moz-animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
  -ms-animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
  -o-animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
  animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 136
  }
}

@-moz-keyframes dash {
  to {
    stroke-dashoffset: 136
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 136
  }
}
// -----------------------

@import "responsive/_desktop.scss";
@import "responsive/_retina.scss";
@import "responsive/_tablet.scss";
@import "responsive/_tmobile.scss";

